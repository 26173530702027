import { useState } from "react";
import Footer from "../components/Footer";

export default function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://vrco.azurewebsites.net/api/ContactUs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        message,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      }
      );
  };

  return (
    <>
      <div className="relative flex items-top justify-center lg:h-[calc(90)] sm:pt-0 bg-zinc-50">
        <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
          <div className="mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="p-6 mr-2 bg-slate-100 sm:rounded-lg">
                <h2 className="text-4xl font-bold leading-tight sm:text-4xl lg:text-5xl text-center  text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-blue-600 py-4">
                  Get in touch
                </h2>
                <p className="text-normal text-lg font-medium mt-2">
                  Have a question or want to chat? Send us a message and we'll contact you as soon as possible.
                </p>
                <div className="flex items-center mt-2 ">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" className="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <div className="ml-4 text-md tracking-wide font-semibold w-40">
                    contact@vrco.site
                  </div>
                </div>
                <p className="text-normal text-sm text-zinc-700 font-medium mt-4">
                  * Keep in mind that the answer may be in your spam folder, so check it regularly
                </p>
              </div>

              <form id="payment-form" className="flex flex-col mx-auto p-8 sm:p-12">
                <div className="flex flex-col">
                  <label for="name" className="hidden">Full Name</label>
                  <input type="name" name="name" id="name" placeholder="Full Name" className="w-100 mt-2 py-3 px-3 rounded-lg bg-slate-200 border border-slate-300 text-black focus:border-indigo-500 focus:outline-none" value={name} onChange={e => setName(e.target.value)} />
                </div>

                <div className="flex flex-col mt-2">
                  <label for="email" className="hidden">Email</label>
                  <input type="email" name="email" id="email" placeholder="Email" className="w-100 mt-2 py-3 px-3 rounded-lg bg-slate-200 border border-slate-300 text-black focus:border-indigo-500 focus:outline-none" value={email} onChange={e => setEmail(e.target.value)} />
                </div>

                <div className="flex flex-col mt-2">
                  <label for="problem" className="hidden">Chat with us</label>
                  <textarea name="problem" id="problem" placeholder="Chat with us" className="w-100 h-48 mt-2 py-3 px-3 rounded-lg bg-slate-200 border border-slate-300 text-black break-all text-left focus:border-indigo-500 focus:outline-none" value={message} onChange={e => setMessage(e.target.value)} />
                </div>

                <div className="flex justify-center mt-3">
                  <button type="submit" className="md:w-48 bg-blue-600 hover:bg-blue-300 text-white font-bold py-3 px-6 rounded-full mt-3 transition ease-in-out duration-300" disabled={!name || !email || !message} onClick={e => handleSubmit(e)}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}