import { useEffect, useContext } from "react";
import { DataContext } from "../context/DataContext";
import { Link, useSearchParams } from "react-router-dom";

export default function Success() {

  const { user } = useContext(DataContext);
  const [searchParams] = useSearchParams(); 
  
  
  useEffect( () => {
    console.log(user);
    if (user && searchParams.get("payment") && searchParams.get("planId")) {
    fetch("https://vrco.azurewebsites.net//api/ConfirmPayment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uid: user.uid,
        email: user.email,
        payment: searchParams.get("payment"),
        planId: searchParams.get("planId"),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      }
    );
  }
  }, [user, searchParams]);

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-8">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Thank you for your purchase!
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          You will receive a receipt in your email shortly. If you have any questions, please contact us at <a className="text-indigo-600" href="mailto:contact@vrco.site">contact@vrco.site</a>. Return to the dashboard to finish setting up your account and to provide us with your property information.
        </p>
      </div>
      <div className="text-center">
        <Link to="/dashboard"
          className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-blue-600 hover:bg-blue-800 focus:shadow-outline focus:outline-none"
        >
          Dashboard
        </Link>
      </div>
    </div>
  );
}
