import { useState, useEffect } from "react";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { resortData } from "../data";
import Footer from "../components/Footer";

export default function RentalService() {
  // rental info
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [property, setProperty] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comments, setComments] = useState("");

  // ResortFilterForm state and handlers
  const [resorts, setResorts] = useState([]);
  const [names, setNames] = useState([]);
  const [destinations, setDestinations] = useState([]);

  const [selectedResort, setSelectedResort] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");

  const handleSubmit = () => {
    fetch("https://vrco.azurewebsites.net//api/rentunit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailAddress: emailAddress,
        destination: property,
        startDate: startDate,
        endDate: endDate,
        comments: comments,
        name: name,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success('Vacation Booking sent', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data);
        setProperty("");
        setStartDate("");
        setEndDate("");
        setComments("");
      });
  };

  useEffect(() => {
    setProperty(selectedResort + " " + selectedName + " " + selectedDestination);
  }, [selectedResort, selectedName, selectedDestination]);

  useEffect(() => {
    console.log(resortData);
    const uniqueResorts = Array.from(
      new Set(
        resortData
          .filter((row) => row.disponibilidad === "Si")
          .map((row) => row.resort)
      )
    );
    setResorts(uniqueResorts);
  }, []);

  useEffect(() => {
    const filteredNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === selectedResort)
          .map((row) => row.name)
      )
    );
    setNames(filteredNames);

    // Set the default value for the "name" input when there is only one option available
    setSelectedName(filteredNames.length === 1 ? filteredNames[0] : "");
  }, [selectedResort]);

  useEffect(() => {
    const filteredDestinations = Array.from(
      new Set(
        resortData
          .filter(
            (row) => row.resort === selectedResort && row.name === selectedName
          )
          .map((row) => row.destino.split(",")) // Split the string by commas
          .flat() // Flatten the resulting array
      )
    );
    setDestinations(filteredDestinations);
    setSelectedDestination(
      filteredDestinations.length === 1 ? filteredDestinations[0] : ""
    );
  }, [selectedResort, selectedName]);

  useEffect(() => {
    if (startDate) {
      // add 7 days to the start date
      const endDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
      // set the end date
      setEndDate(endDate);
    }
  }, [startDate]);

  return (
    <>
      <section className=" bg-zinc-50">
        <div className="terms px-8 md:px-30 xl:px-60 pb-12">
          <div className="rental-info">
            <h1 className="text-4xl font-bold leading-tight sm:text-4xl lg:text-5xl text-center  text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-blue-600 pt-12">
              Vacation Booking
            </h1>
            <p className="mt-4 text-lg pb-12 text-center">
              This section is for those interested in renting a vacation property.
            </p>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-3/12">
                <label className="block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Full Name
                </label>
              </div>
              <div className="md:w-3/5">
                <input className="bg-slate-200 appearance-none border-2 border-slate-300 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:border-indigo-500 " id="inline-full-name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-3/12">
                <label className="block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Email
                </label>
              </div>
              <div className="md:w-3/5">
                <input className="bg-slate-200 appearance-none border-2 border-slate-300 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:border-indigo-500" id="inline-full-name" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-3/12">
                <label className="block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Resort
                </label>
              </div>
              <div className="md:w-3/5">
                <select
                  id="resort"
                  value={selectedResort}
                  onChange={(e) => setSelectedResort(e.target.value)}
                  className="bg-slate-200 appearance-none border-2 border-slate-300 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:border-indigo-500"
                >
                  <option value="">Select a resort</option>
                  {resorts.map((resort, index) => (
                    <option key={index} value={resort}>
                      {resort}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-3/12">
                <label className="block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Property
                </label>
              </div>
              <div className="md:w-3/5">
                <input
                  id="name"
                  value={selectedName}
                  onChange={(e) => setSelectedName(e.target.value)}
                  className="bg-slate-200 appearance-none border-2 border-slate-300 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:border-indigo-500 disabled:opacity-50"
                  list="name-datalist"
                  disabled={!selectedResort}
                  autoComplete="off"
                  placeholder="Select a property"
                />
                <datalist id="name-datalist">
                  {names.map((name, index) => (
                    <option key={index} value={name} />
                  ))}
                </datalist>
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-3/12">
                <label className="block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Destination
                </label>
              </div>
              <div className="md:w-3/5">
                <select
                  id="destination"
                  value={selectedDestination}
                  onChange={(e) => setSelectedDestination(e.target.value)}
                  className="bg-slate-200 appearance-none border-2 border-slate-300 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:border-indigo-500 disabled:opacity-50"
                  disabled={!selectedResort || !selectedName}
                >
                  <option value="">Select a destination</option>
                  {destinations.map((destination, index) => (
                    <option key={index} value={destination}>
                      {destination}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-3/12">
                <label className="block md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                  Start Date
                </label>
              </div>
              <div className="md:w-3/5">
                <input className="bg-slate-200 appearance-none border-2 border-slate-300 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:border-indigo-500" id="inline-full-name" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-3/12">
                <label className="block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                  End Date
                </label>
              </div>
              <div className="md:w-3/5">
                <input className="bg-slate-200 appearance-none border-2 border-slate-300 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:border-indigo-500" id="inline-full-name" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} disabled={true} />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-3/12">
                <label className="block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Comments (optional)
                </label>
              </div>
              <div className="md:w-3/5">
                <input className="bg-slate-200 appearance-none h-40 border-2 border-s3ate-500 rounded w-full py-2 hite leading-tight focus:outline-none focus:border-indigo-500" id="inline-full-name" type="text" value={comments} onChange={(e) => setComments(e.target.value)} />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <p className="text-gray-700 text-center text-sm">* Keep in mind that the answer may be in your spam folder, so check it regularly</p>
            </div>
            <button className="main btn btn-primary" onClick={handleSubmit} disabled={!(emailAddress && name && selectedResort && selectedDestination && selectedName && startDate && endDate)}>
              Send
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}