import React, { createContext, useState } from "react";

export const DataContext = createContext();

export const DataProvider = (props) => {
  const [app, setApp] = useState(null);
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [payment, setPayment] = useState(null);

  return (
    <DataContext.Provider value={{app, setApp, auth, setAuth, user, setUser, payment, setPayment}}>
      {props.children}
    </DataContext.Provider>
  );
}

