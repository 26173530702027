export default function Footer () {

  const year = new Date().getFullYear();

  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            <img src="/logo-white.png" alt="" className="w-40" />
          </a>
        </div>
        <div className="space-y-2 text-sm">
        </div>
        <div>
          <span className="text-lg font-semibold tracking-wide text-blue-300">
            Contact
          </span>
          <div className="flex">
            <p className="mr-1">Email:</p>
            <a
              href="mailto:contact@vrco.site"
              aria-label="Our email"
              title="Our email"
              className="font-semibold transition-colors duration-300 text-blue-600"
            >
              contact@vrco.site
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        <p className="text-sm text-gray-600">
          © Copyright {year} Vacation Rental Connections. All rights reserved.
        </p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <a
              href="/privacy"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-sky-500"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <a
              href="/terms"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-sky-500"
            >
              Terms &amp; Conditions
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};