// import { Link } from "react-router-dom";
import Feature from "./Feature";
import Map from "./Map";
import { PrimaryFeatures } from "./PrimaryFeatures";
// import { AnimatedBeamDemo } from "./beam";

export default function MoreInfo() {
  return (
    <section className="">
      <div className="px-4 pb-16 mx-auto max-w-screen-xl sm:px-6 lg:px-8 text-center">
        <h2 className="text-4xl font-bold leading-tight sm:text-4xl lg:text-5xl text-center  text-transparent bg-clip-text bg-gradient-to-br gradient-text md:py-2">Why do you need VRCo?</h2>
        <div className="max-w-xl mx-auto text-center pb-2">
          <p className="mt-4 text-lg">
            VRCo efficiently promotes your unbooked weeks on major rental websites, while simultaneously serving as a platform that connects discerning clients seeking exclusive residences with owners eager to market their unused properties.
          </p>
        </div>

        <PrimaryFeatures />

        {/* <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div
            className="block p-8 border border-indigo-400 shadow-lg rounded-xl hover:shadow-indigo-500 hover transition duration-500 ease-in-out">
            <img src="/users-svgrepo-com.svg" alt="user" className="w-12 filter-indigo mx-auto" />
            <h3 className="mt-4 text-xl font-bold">User friendly</h3>
            <p className="mt-1 text-sm">
              VRCo can be used by anybody. We have a very user-friendly interface that allows you to manage your reservations and follow up with your rentals at any time.
            </p>
          </div>
          <div
            className="block p-8 border border-indigo-400 shadow-lg rounded-xl hover:shadow-indigo-500 hover transition duration-500 ease-in-out">
            <img src="/three-arrows-svgrepo-com.svg" alt="user" className="w-12 filter-indigo mx-auto" />
            <h3 className="mt-4 text-xl font-bold">Flexible</h3>
            <p className="mt-1 text-sm">
              Manage your reservations at any time (cancel or reschedule). VRCo will allow you to manage your reservations and rentals with ease.
            </p>
          </div>
          <div
            className="block p-8 border border-indigo-400 shadow-lg rounded-xl hover:shadow-indigo-500 hover transition duration-500 ease-in-out">
            <img src="/security-on-svgrepo-com.svg" alt="user" className="w-12 filter-indigo mx-auto" />
            <h3 className="mt-4 text-xl font-bold">Secure</h3>
            <p className="mt-1 text-sm">
              VRCo is secure and safe to use. We use the latest security protocols to ensure that your data is safe and secure.
            </p>
          </div>
        </div> */}
        {/* <AnimatedBeamDemo /> */}
        {/* <Feature /> */}
        <Map />
      </div>
    </section>
  )
}