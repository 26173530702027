
const logos = [
  { id: 1, src: 'luxv2.png', alt: 'Lux Rental' },
  { id: 3, src: 'luxe.png', alt: 'Luxe' },
  { id: 4, src: 'airbnb.png', alt: 'Airbnb' },
  // { id: 5, src: 'vrbo.png', alt: 'Vrbo' },
  // ... add more logos
];

export default function Companies() {
  return (
    <section className="text-gray-800 text-center container mx-auto">
      <h2 className="text-4xl font-bold leading-tight sm:text-4xl lg:text-5xl text-center  text-transparent gradient-text md:py-2">Collaborations & Promotions</h2>
      <div className="flex flex-col md:flex-row justify-center items-center gap-20 mx-10 md:mx-20 my-5">
        {logos.map(logo => (
          <div
            key={logo.id}
            className="logo-container p-4 transform hover:scale-125 transition duration-300 ease-in-out"
          >
            <img
              className="mx-auto w-44 h-auto"
              src={logo.src}
              alt={logo.alt}
            />
          </div>
        ))}
      </div>
    </section>
  )
}