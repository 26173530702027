import { useContext, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, browserLocalPersistence, setPersistence, GoogleAuthProvider, signInWithPopup, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { DataContext } from '../context/DataContext';
import { Link, useNavigate } from "react-router-dom";
import googleLogo from '../assets/google_logo.svg'

export default function Login() {

  const { auth, setAuth, user, setUser } = useContext(DataContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  setAuth(getAuth());

  const navigate = useNavigate();

  const resetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('Password reset email sent!')
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return (
    <section className="flex">
      <div className="container m-auto">
        <div className="flex justify-center h-full g-6 text-gray-800 pt-24">
          <div className="flex flex-col w-11/12 lg:w-5/12 justify-center items-center ">
            <form className='w-full shadow-[0px_0px_0px_0.5px_rgba(50,50,93,0.1),0px_2px_5px_0px_rgba(50,50,93,0.1),0px_1px_1.5px_0px_rgba(0,0,0,0.07)] rounded-lg p-8'>
              <div className="mb-6">
                <input
                  type="text"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <input
                  type="password"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                  <strong className="font-bold">The user or password is incorrect!</strong>
                </div>
              )}

              <div className="flex justify-between items-center mb-6">
                {/* <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    id="exampleCheck3"
                  />
                  <label className="form-check-label inline-block text-gray-800" for="exampleCheck2"
                  >Remember me</label
                  >
                </div> */}
                <a
                  type="button"
                  onClick={resetPassword}
                  className="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out cursor-pointer"
                >
                  Forgot password?
                </a>
              </div>
              <button
                type="submit"
                className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full mb-2"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                onClick={(e) => {
                  e.preventDefault();
                  setPersistence(auth, browserLocalPersistence)
                    .then(() => {
                      return signInWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                          // Signed in
                          const user = userCredential.user;
                          setUser(user);
                          console.log(user);
                          navigate('/dashboard');
                        })
                        .catch((error) => {
                          setError(error.message);
                        })
                    })
                }}
              >
                Login
              </button>

              <button
                type="submit"
                className="inline-block px-7 py-3 bg-gray-50 font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-200 active:shadow-lg transition duration-150 ease-in-out w-full text-black"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                onClick={(e) => {
                  e.preventDefault();
                  setPersistence(auth, browserLocalPersistence)
                    .then(() => {
                      const provider = new GoogleAuthProvider();
                      return signInWithPopup(auth, provider)
                        .then((result) => {
                          // This gives you a Google Access Token. You can use it to access the Google API.
                          const credential = GoogleAuthProvider.credentialFromResult(result);
                          const token = credential.accessToken;
                          // The signed-in user info.
                          const user = result.user;
                          setUser(user);
                          console.log(user);
                          navigate('/dashboard');
                        }).catch((error) => {
                          // Handle Errors here.
                          setError(error.message);
                          // The AuthCredential type that was used.
                          const credential = GoogleAuthProvider.credentialFromError(error);
                        });
                    })
                }}
              >
                <img src={googleLogo} alt="google logo" className="inline-block mr-2" />
                Continue with Google
              </button>
            </form>
          </div>
        </div>
        <div className="text-center mt-4">
          <p className="text-gray-800">
            Don't have an account?
            <Link
              type="button"
              className="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out cursor-pointer"
              to="/signup"
            >
              &nbsp;Sign up
            </Link>
          </p>
        </div>
      </div>
    </section>
  )
}