import { useContext, useEffect, useState } from "react";

import { DataContext } from "../context/DataContext";

export default function RentalAgreement() {

  const { user } = useContext(DataContext)

  const [percentage, setPercentage] = useState("twenty-five percent (25%)");

  useEffect(() => {
    if (user) {
      // console.log(user.uid);
      // switch (user.uid) {
      //   case "47Rge6UxLGYCa1Qgh7Z0ywTH3rR2":
      //     setPercentage("ten percent (10%)");
      //     break;
      //   case "CkbHaYkuMrNrXXMLvxJWvG7Z58C2":
      //     setPercentage("fifteen percent (15%)");
      //     break;
      //   case "B05MbE0TqKQ2unVC9DxbhEp7jMp2":
      //     setPercentage("fifteen percent (15%)");
      //     break;
      //   case "6oEOd3pSFBXFydRcOEvk4qGv9WD2":
      //     setPercentage("twenty percent (20%)");
      //     break;
      //   case "OJmS5wESu0Y6aQ0C6mQScvpZv2A3":
      //     setPercentage("ten percent (10%)");
      //     break;
      //   case "7VHekLtjbxSynh8fgCusuxdCfn72":
      //     setPercentage("ten percent (10%)");
      //     break;
      //   case "6EZZRnCR7nVbHRf9xy5JHVrC1Hk2":
      //     setPercentage("ten percent (10%)");
      //     break;
      //   case "GaoYTFEFhkRT1KJxvvceRXOnlaE3":
      //     setPercentage("fifteen percent (15%)");
      //     break;
      //   case "tWQDjskqLvYmTgWJD5n2PCuMch43":
      //     setPercentage("fifteen percent (15%)");
      //     break;
      //   default:
      //     setPercentage("twenty-five percent (25%)");
      // }
      fetch(`https://vrco.azurewebsites.net/api/GetPercentage?uid=${user.uid}`)
        .then((res) => res.json())
        .then((data) => {
          const percentage = data[0][0]
          if (percentage === "10") {
            setPercentage("ten percent (10%)");
          } else if (percentage === "15") {
            setPercentage("fifteen percent (15%)");
          } else if (percentage === "20") {
            setPercentage("twenty percent (20%)");
          } else {
            setPercentage("twenty-five percent (25%)");
          }
        });
    }
  }, [user]);

  return (
    <>
      <p className="" ><span className="font-bold">PROPERTY	RENTAL	AGREEMENT</span>	(the "Agreement") dated on the Effective Date, by and among the following parties: </p>
      <p className="" >(1)	The Client; and </p>
      <p className="" >(2)	Vacation Rental Connections ("VRCo" and together with the Client, the "Parties"). </p>
      <p className="" >In accordance with the following Recitals and Clauses. </p>
      <p className="" >Capitalized terms shall have the meanings ascribed herein and in Exhibit "A", as applicable. </p>
      <p className="font-bold py-2" >RECITALS </p>
      <p className="" >I.	Client's Recitals. The Client declares, under oath, that: </p>
      <p className="" >(a)	It is the sole and legitimate owner of the rights of use, possession and enjoyment of the unit interests and use periods or weeks available (“Unit Interval”) over the Property. </p>
      <p className="" >(b)	Has all the necessary legal and contractual authorizations and has full legal capacity and sufficient authority to enter into this Agreement and be bound by its terms. </p>
      <p className="" >(c)	The execution, delivery and performance of this Agreement does not violate any Applicable Law. </p>
      <p className="" >(d)	There are no claims or threatened claims affecting or likely to affect the unit interests and Unit Interval's or use periods available over the Property or the performance of its obligations under this Agreement. </p>
      <p className="" >(e)	It has the exclusive and irrevocable right to promote the Unit over the Property to individuals other than the Client, on the dates indicated by the Client in terms of Section 1.2 below. </p>
      <p className="" >(f)	Is exclusively responsible for making any service, annuity, maintenance or other payments required to use the Property or the Unit Interval. </p>
      <p className="" >II.	Managers' Recitals. The Manager declares, that: </p>
      <p className="" >(a)	Your comprehensive privacy notice is available for review by any Person, including </p>
      <p className="" >without limitation the Client and Final Users, at any time on the website www.vrco.site. </p>
      <p className="" >(b)	Has no relation whatsoever with the Client and in connection with the Property, and that his relationship is exclusively in his capacity as commission agent in charge of promote the temporary use, possession and enjoyment of the unit interests and use periods available over the Property. </p>
      <p className="" >PURSUANT TO THE FOREGOING, based on </p>
      <p className="" >the Declarations contained in this Agreement, the Parties grant the following: </p>
      <p className="font-bold py-2" >CLAUSES </p>
      <p className="font-bold pt-2" >CLAUSE ONE. APPOINTMENT OF THE MANAGER. </p>
      <p className="" >Section 1.1. Appointment of Manager, and Agent. The Parties agree that the Manager shall provide the Services described in Section Four of this Agreement to the Client, and the Client agrees to pay the Management Fee to the Manager in terms of the provisions of Section 3 of this Agreement. </p>
      <p className="" >The Client irrevocably authorizes the Manager, to: (i) publish the Clients' Unit Interval on Third Party Platforms, (ii) offer and market to Final Users the Clients' Unit Interval on Third Party Trading Platforms, and (iii) cause to place the Unit Interval available to the Final User by the Client. </p>
      <p className="" >The Parties agree that the Manager is not the supplier of the Unit Interval, and that Manager shall act in the name and account of the Client, without the Manager being construed to have any obligation towards the Final Users. The Managers does not represent, or in any way act, for either the Client or the Final Users, for the above, the Managers does not have control over the ability to rent the Unit Interval to Final Users, nor the ability of Final Users to acquire or complete their reservation. </p>
      <p className="" >The Commercial Commission is irrevocable by virtue of being a necessary means for the Manager to comply with its obligations under this Agreement in terms of article 2596 of the Civil Code of the Federal District (Distrito Federal), applicable in Mexico City, article 2596 of the Federal Civil Code and its correlatives in the other Mexican Federal Entities. </p>
      <p className="" >The Client irrevocably authorizes the Manager to delegate its powers conferred under the Commercial Commission. </p>
      <p className="" >Section 1.2. Unit Interval's. The Unit Interval may be used, pursuant to the Permitted Use, by the Final Users or by persons expressly authorized by the Final Users, on the dates or on the number of weeks indicated by the Client at the time of its registration in the Manager's Platform (which may be amended from time to time, provided that such amendment of dates does not affect Final Users, pursuant to Clause Six below). </p>
      <p className="" >Furthermore, the Parties agree that the Services covered under this Agreement shall only be rendered in connection with the Property and/or Properties that have been specified and selected at the time of payment of the Enrollment Fee. The Manager reserves its right to accept additional properties from the Client or to substitute the Properties previously described at the time of payment of the Enrollment Fee, until it receives from the Client the payment of the corresponding fee. </p>
      <p className="" >Section 1.3. Revenues, Costs and Expenses. The Parties agree that, during the Term, the Manager shall be the sole individual entitled to collect all income, proceeds, rents, amounts resulting from renting the Client's Unit Interval over the Property. </p>
      <p className="font-bold pt-2" >CLAUSE TWO. VALIDITY. </p>
      <p className="" >The term ("Term") of this Agreement shall commence on the Effective Date and shall remain in effect indefinitely until either Party gives the other Party at least 60 (sixty) calendar days prior notice of termination, or in the event that this Agreement is terminated in accordance with the termination provisions set forth herein. </p>
      <p className="font-bold pt-2" >CLAUSE THREE. FEES; GUIDELINES; TAXES PAYMENT. </p>
      <p className="" >Section 3.1. Fees. During the Term of this Agreement, the Client agrees to pay the Manager the Administration Fee, plus VAT as its fees for the provision of the Services. </p>
      <p className="" >The Parties agree that the Manager shall have the right to collect the Client's Amounts, cancellation fees, and any other amounts in connection with the Property or the Unit Interval over the Property, and any applicable Taxes in connection with the foregoing, during the Term. </p>
      <p className="" >Section 3.2. Guidelines. The Parties agree that the Manager shall deliver to the Client, the Client's Amounts after applying to the payment or reimbursement, the amounts corresponding to the Administration Fee and the Administration Expenses, respectively. </p>
      <p className="" >The Parties agree that payment of the Client's Amounts shall be made by wire transfer to the Client Account as soon as possible, but in any event within </p>
      <p className="" >30 (Thirty) Business Days after the Final Users completed his/her stay at the Property. The Client irrevocably authorizes and entitles the Manager to withhold any amount payable by the Manager to the Client and/or to charge such amounts to the payment method registered by the Client with the Manager or any other payment method available, at any time under this Agreement and apply it to the payment of any amount owed (now or in the future) by the Client to the Manager for any reason whatsoever, without any notice, requisition or demand whatsoever. </p>
      <p className="" >Section 3.3. Taxes. Client acknowledges that the applicable Taxes, including VAT and applicable lodging tax, in terms of Applicable Law in connection with any amounts payable by Final Users to Client and/or from the Client to the Manager under this Agreement, are for the account of Client. </p>
      <p className="" >The Client irrevocably authorizes the Manager to collect from the Final Users and the Client, any taxes due in connection with any amounts payable to the Manager under this Agreement. </p>
      <p className="" >The Client hereby releases the Manager from any obligation and acknowledges that the Manager shall not be liable in any way if the corresponding Taxes are not paid correctly. </p>
      <p className="font-bold pt-2" >CLAUSE FOUR. MANAGER'S OBLIGATIONS. </p>
      <p className="" >During the Term of this Agreement, the Manager agrees to provide the following services (the "Services"): </p>
      <p className="" >(a)	To provide accommodation management services, including without limitation the reservation services, and to cause the Client to provide to each Final User the reservation and cancellation policies and rules, as defined by the Manager from time to time. In this regard, the Manager does not guarantee the rental or reservation from Final Users of the Property or the Unit Interval over the Property. </p>
      <p className="" >(b)	Set and determine and collect the amount of the Retail Price and/or the corresponding cancellation fee and any other amounts payable by the Final User in connection with the Property or the Unit Interval’s, in addition to any other revenues in connection with the Property. </p>
      <p className="" >(c)	Negotiate and enter into the necessary agreements between the Client and the Final Users, that allow the Final Users to have the right to temporary use, possess and enjoy of the Property or the Unit Interval in accordance with the Permitted Use. </p>
      <p className="" >(d)	To make the necessary arrangements for the collection of the Retail Price, the Cancellation Fee and any other amounts payable to the Client in connection with the Property. </p>
      <p className="" >(e)	Transfer to the Client Account the Client Amounts. </p>
      <p className="" >(f)	Report to the Client by delivering the Report in terms of Clause Three above. </p>
      <p className="font-bold pt-2" >CLAUSE FIVE. CLIENT'S OBLIGATIONS. </p>
      <p className="" >During the Term of this Agreement, the Client undertakes to comply in a timely and efficient manner with the following obligations: </p>
      <p className="" >(a)	Provide the Final Users with the Property and/or Unit Interval's over the Property, on the dates selected by the Final Users, from the Effective Date and during the Term of this Agreement. </p>
      <p className="" >(b)	At the request of the Manager, to cause the resort or provider of tourism services to assign the Property and/or Unit Interval to the Final Users during the dates chosen by the Final Users. </p>
      <p className="" >(c)	Pay in due time the Administration Fee, the corresponding Taxes, and any other amounts. </p>
      <p className="" >(d)	Not to sell, donate, encumber or in any way modify the right it has over the Property and/or the Unit Interval during the Term. </p>
      <p className="" >(e)	To enter into and perform all the necessary duties requested by the Manager in connection with the Services and the management of the Property, and to deliver all information and documentation requested by the Manager in accordance with such requests. </p>
      <p className="" >(f)	That	the	Client's	Account	allows receiving payments in the legal currency in Mexico. </p>
      <p className="" >In this regard, the Parties acknowledge that the payments to be made by the Manager to the Client related to the Client's Amounts shall be made in the legal currency of Mexico; therefore, regardless the fact that such amounts can or may be expressed in dollars of the United States of America, they will be considered to be paid in full, in case it is paid in Pesos at the exchange rate published by the Bank of Mexico on the date of the corresponding payment. </p>
      <p className="" >In the event that the Client does not have an account that allows the reception of payments in Mexican pesos, the Manager shall be entitled to withhold the amounts corresponding to the Client's Amounts, until the Client's Account allows the reception of payments in Mexican pesos. </p>
      <p className="font-bold pb-1" >CLAUSE SIX. CHANGES; CANCELLATIONS. </p>
      <p className="" >The Client agrees to use its best efforts to avoid making changes or cancellations to Existing Reservations. If the Client is required to make any changes or cancellations to Existing Reservations, the Client undertakes to the Final Users and the Manager to pay any costs, expenses or penalties, which may be charged by Final Users, Third Party Platforms or any other party related, as a result of the abovementioned. </p>
      <p className="" >Additionally, the Client accepts that if the Final User cancels an Existing Reservation, the Manager will collect from the Final User, and pay the Client the cancellation fee or, if applicable, the Usage Fee, in accordance with the terms and conditions established in Third Party Platforms. </p>
      <p className="font-bold pt-2" >CLAUSE SEVEN. PROMOTIONAL MATERIALS. </p>
      <p className="" >The Client agrees not to issue advertisements, publications or promotional material referring to the Manager, its trade name, intellectual property, properties, or assets, without the prior written consent of the Manager. The Client irrevocably authorizes the Manager to issue advertisements, publications and promotional material referring to this Agreement, the Client, the Property, its location and characteristics and any other information it deems convenient. </p>
      <p className="" >Furthermore, the Manager acknowledges that the Client shall have the right, but not the obligation, to advertise on Third Party Platforms and any other websites, applications or services ("Alternative Options"), the Property and the Unit Interval over the Property without the Manager's prior written consent. Notwithstanding the above, the Client undertakes to keep the rental price advertised in the Alternative Options identical to the rental price advertised by the Manager on Third Party Platforms. </p>
      <p className="font-bold pt-2" >CLAUSE EIGHT. CAUSES FOR EARLY TERMINATION. </p>
      <p className="" >The Parties agree that the Manager may terminate this Agreement early without liability and without the need for a judicial declaration upon the occurrence of any of the following events (the "Event of Default"): </p>
      <p className="" >(a)	If the Client fails to comply with any of its obligations under this Agreement or the Applicable Law. </p>
      <p className="" >(b)	If the Property or Unit Interval cannot be used by the Manager or the Final Users in accordance with the Permitted Use. </p>
      <p className="" >(c)	If the Property or the Unit Interval ceases to be owned by the Client. </p>
      <p className="" >(d)	If the Client obstructs, hinders, delays, prevents, opposes or in any other way complicates the exercise of any of the Manager's or Final Users' rights under this Agreement or Applicable Law. </p>
      <p className="" >(e)	If any statement made by the Client becomes untrue or inaccurate during the Term of this Agreement. </p>
      <p className="" >(f)	If the Client advertises the Property and/or the Unit Interval over the Property in any Alternative Option at a different price than the one used by the Manager at Third Party Platforms. </p>
      <p className="" >(g)	The existence of any indication of money laundering or the execution of transaction with illicit origin resources by the Client or individuals related to the Client. </p>
      <p className="" >In the event of the occurrence of any Event of Default, the Manager and the Final Users shall be entitled to collect damages from the Client. </p>
      <p className="font-bold pt-2" >CLAUSE	NINE.	INDEPENDENT CONTRACTOR. </p>
      <p className="" >Nothing contained in this Agreement shall be construed as having created, nor shall it be construed as having created, a joint venture or partnership relationship between the Client and the Manager. </p>
      <p className="font-bold pt-2" >CLAUSE TEN. INDEMNIFICATION. </p>
      <p className="" >The Client agrees to properly protect, indemnify and hold the Manager harmless from and against any and all Claims, EITHER FROM Final Users OR THIRD PARTIES (including, without limitation, legal fees and expenses) arising out of, and for any and all damages and losses suffered, in connection with (i) any Client’s breach to the provisions set forth in this Agreement; (ii) any breach of the Applicable Law by the Client or any Person acting on its behalf or at its direction; (iii) the acts or omissions of the Client or any Person acting on its behalf or pursuant to its instructions; </p>
      <p className="" >(iv) the use, possession or enjoyment of the Property or Unit Interval by the Manager or the Final Users; (v) any representation made by the Client in this Agreement which is untrue, incomplete or inaccurate; or (vi) the occurrence of any act not permitted or authorized pursuant to this Agreement and which affects the Manager in any way. </p>
      <p className="" >CLAUSE ELEVEN. MISCELLANEOUS. </p>
      <p className="" >Section 11.1. Notices and Notices. All notices, demands, consents, approvals, waivers or other communications required to be given by a Party to the other Party pursuant to this Agreement shall be in writing and to the email address indicated by the Client at the time of payment of the Enrollment Fee. </p>
      <p className="" >Section 11.2. Applicable Law and Jurisdiction. For all matters related to the interpretation and performance of this Agreement, as well as for the resolution of any controversy arising hereunder, the Parties agree that the laws of Mexico City shall be applicable. The Parties expressly and irrevocably agree to submit any controversy arising from the interpretation or breach of this Agreement to the competent courts located in Mexico City, Mexico, expressly, terminally, and irrevocably waiving any other jurisdiction or venue that may correspond to them by virtue of their present or future domiciles or for any other reason. </p>
      <p className="font-bold py-2" >Exhibit "A". </p>
      <p className="font-bold pt-2" >Defined Terms </p>
      <p className="" >The Parties agree that the following capitalized terms shall have the respective meanings indicated below: </p>
      <p className="" >"Administration Expenses" means all costs and expenses incurred by the Manager in connection with the provision of the Services. </p>
      <p className="" >"Administration Fee" is equal to {percentage} of the result of deducting the Usage Fee from the Retail Price. The formula for calculating the Administration Fee is as follows: </p>
      <p className="" >Administration Fee = (Retail Price - Usage Fee) * {percentage.split(" ")[2]} </p>
      <p className="" >"Applicable Law" means, with respect to any Person, any law, treaty, statute, rule, decree, regulation, official rule, order, arbitral award or judicial resolution applicable in Mexico. </p>
      <p className="" >"Business Day" means any day on which banks in Mexico are not required to close their doors or suspend operations in terms of Applicable Law. </p>
      <p className="" >"Cancellation Fee" means the amount set by the Manager to be paid by the Final User to the Manager in the event of cancellation of a reservation in accordance with the terms and conditions agreed between the Final User and the Manager. </p>
      <p className="" >"Claims" means with respect to a Person, any loss, liability, damage, injury, claim, expense, obligation, penalty, fine, action, judgment, arbitration award, court decision, administrative ruling, ruling of any Governmental Authority, demand, cost or disbursement and the like, however arising, including without limitation reasonable fees and reasonable and properly documented expenses incurred by its advisors. </p>
      <p className="" >“Client” means the individual or legal entity owning the Property and/or the Unit Interval, who is registered on the Manager's platform at the time of payment of the Enrollment Fee. </p>
      <p className="" >"Client Account" means the bank account indicated by the Client to VRCo, into which the Client Amount shall be deposited. </p>
      <p className="" >"Client Amounts" means the amount that the Manager will transfer to the Client's Account and which corresponds to (i) the total of the Retail Price, plus the corresponding Third Party Platforms' cancellation fees, plus any other income related to the Property, minus (ii) the Administration Fee, minus (iii) the Administration Expenses; minus (iv) the applicable Taxes; if applicable, minus (v) any applicable fees for transferring funds outside of Mexico or in foreign currency. </p>
      <p className="" >“Effective Date” means the date on which the Client pays the Enrollment Fee for the services offered by VRCo, and which corresponds to the effective date of this Agreement. </p>
      <p className="" >“Enrollment Fee” means the initial fee to be paid by the Client to VRCo for the provision of the services provided under this Agreement. </p>
      <p className="" >“Existing Reservation” means the reservation made by a Final User in order to temporary use, posses and enjoy the Property and/or the Unit Interval. </p>
      <p className="" >"Final User" means any Person who enters into any contract in connection with the Property or the Unit Interval over the Property, and who is obligated to pay the Retail Price to the Client. </p>
      <p className="" >"Governmental Authority" means any administrative, legislative or judicial authority in Mexico or abroad whether federal, state or municipal, as well as any other body in Mexico. </p>
      <p className="" >"Mexico" means the United Mexican States. </p>
      <p className="" >"Permitted Use" means any lawful use (excluding industrial) including without limitation the temporary use, possession and/or enjoyment of the Property or the Unit Interval. </p>
      <p className="" >"Property" means the Unit Interval over the property and/or properties specified and hired by the Client at the time of paying his/her Enrollment Fee, or hired after the registration, and which is registered in the Manager's platform as a property, including its surface, subsoil and subway water, if applicable. </p>
      <p className="" >"Retail Price" means the amount established by the Manager to be paid by the Final Users for the temporary use, lease, lodging or similar of the Unit Interval over the Property or a portion thereof, which does not include the applicable lodging tax. </p>
      <p className="" >"Taxes" means any tax, duty, levy, contribution, tribute, withholding, deduction, charge or other tax liability imposed on such amounts in Mexico or abroad now or hereafter during the term of this Agreement. </p>
      <p className="" >"VAT" means Value Added Tax. </p>
      <p className="" >“Third Party Platforms” means the technological platforms of the tourism service providers, through which VRCo carries out the offering and commercialization of the Properties and Unit Interval's. </p>
      <p className="" >“Usage Fee” means the amount that the Client pays to the Resort in order to be able to use, possess and enjoy the Property in the Unit Interval booked. </p>
    </>
  );
}
