export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto mt-12">
      <div className="terms px-8 md:px-30 xl:px-80 py-8 text-justify">
<p className="">Privacy Notice </p>
<p className="">1.	Identity and Address of the Data Controller. </p>
<p className="">Vacation Rental Connections (“VRCo”), with domicile in Mexico City, Mexico as data controller (as defined below), for the processing of Personal Data, hereby provides to the data subject (as defined below) this privacy notice (the “Privacy Notice”) in compliance with the Federal Law on Protection of Personal Data Held by Private Parties (Ley Federal de Protección de Datos Personales en Posesión de los Particulares) (the “Law”), its Regulations (Reglamento) (the “Regulations”) and the Privacy Notice Guidelines (Lineamientos del Aviso de Privacidad) (the “Guidelines”). </p>
<p className="">2.	Definitions. </p>
<p className="">The terms used in this Privacy Notice that are not defined herein shall have the meaning attributed to them in the Law: </p>
<p className="">2.1	Personal Data: Any information of an identified or identifiable person including, among others: name, age, date of birth, nationality, address, sex, tax identification number (Registro Federal de Contribuyentes, or RFC), CURP, telephone number, email and official ID. </p>
<p className="">2.2	Sensitive Personal Data: Data that affects the inner scope of the Data Subject or the use of which may cause discrimination or a serious risk for the Data Subject, including, among others: bank account, credit card, information on the properties offered, financial information, that are necessary for rendering the Services. </p>
<p className="">2.3	ARCO Rights: The rights of access, rectification, cancellation, and opposition that, in accordance with the provisions of the Law and subject to the exceptions set forth herein and in this Privacy Notice, each Data Subject has in relation to the Personal Data collected by the Data Controller and/or the Data Processors, which are described below: </p>
<p className="">a.	“Right of Access”: It is the right of the Data Subject to know about the Personal Data related to him/her that is in the possession of the Data Controller, the third parties with whom they have shared it and the purpose of sharing such Personal Data. </p>
<p className="">b.	“Right to Rectification”: Each Data Subject has the right to have his/her Personal Data rectified when it is inaccurate or incomplete. </p>
<p className="">c.	“Right of Cancellation”: Each Data Subject has the right to request at any time that his/her Personal Data be deleted when he/she considers that they are not being processed in accordance with the Law, Regulations, Guidelines, and/or the Privacy Notice. </p>
<p className="">d.	“Right of Opposition”: The Data Subject has at any time the right to request that the Data Controller stop processing his/her Personal Data, provided he/she has a legitimate cause. </p>
<p className="">2.4	Data Processor: The individual or entity who individually, or jointly with others, processes Personal Data on behalf of the Data Controller. </p>
<p className="">2.5	Purposes: The purposes described in this Privacy Notice, for which the Data Controller shall process the Personal Data of the Data Subject. </p>
<p className="">2.6	Data Controller: The individual or entity that decides on the processing of the Data Subject's Personal Data, in this case VRCo. </p>
<p className="">2.7	Data Subject: The individual who is the owner of the Personal Data or is authorized to provide Personal Data of a third party in accordance with the applicable laws, and who provides such Personal Data to the Data Controller, in this case “you”. </p>
<p className="">3.	Personal Data. </p>
<p className="">In order to carry out the Purposes described in this Privacy Notice, the Data Controller may collect and process the following Personal Data: </p>
<p className="">a.	Identification Data: name, age, date of birth, nationality, address, gender, tax identification number (Registro Federal de Contribuyentes, or RFC), CURP, telephone number, email, official ID, username and password. </p>
<p className="">b.	Financial or Patrimonial Data: bank information, card number, expiration, card security code, billing address, information and value relating to the properties and any other financial or proprietary information that is necessary to fulfill the purposes of this Privacy Notice. </p>
<p className="">c.	Other Data: purchase history and contact information of friends, family and other people related. </p>
<p className="">4.	Sensitive Personal Data. </p>
<p className="">In order to carry out the Purposes described in the Privacy Notice, the Data Controller may collect and process the following Sensitive Personal Data: bank information, card number, expiration, card security code, billing address, information and value relating to the properties and any other financial or proprietary information. </p>
<p className="">5.	Primary Purposes. </p>
<p className="">The Personal Data provided to the Data Controller directly by the Data Subject through social media, website, online advertising, cookies, web server logs, web beacons or other electronic means, as a result of the relationship that exists with the Data Subject, will be used for the following Primary Purposes: </p>
<p className="">•	Provide the products or services the client has requested and operate features on the website. </p>
<p className="">•	Process payment card transactions. </p>
<p className="">•	Create and manage online accounts, including access to online and in-store purchase history. </p>
<p className="">•	Manage the property reservation system for the benefit of users, owners and clients. </p>
<p className="">•	Identification and verification of the identity of users, owners and/or clients. </p>
<p className="">•	Sending information on the use of the property, lodging, acquisition of products and/or contracting of services offered. </p>
<p className="">•	Process payments and transfer from o to users, owners and/or clients, as payments for services rendered and/or to purchase additional products and services. </p>
<p className="">•	Assist with product selection and replenishment. </p>
<p className="">•	Chat or respond to inquiries. </p>
<p className="">•	To create a real estate catalog within a technology platform by means of a website, mobile application or through any other type, for the provision of services. </p>
<p className="">•	To reproduce photographs, videos, plans, diagrams, sketches, renders and any material of the properties. </p>
<p className="">•	To advertise and/or offer through any means the properties for rent or use. </p>
<p className="">•	Communicate with costumers, and administer reservation in properties, loyalty programs, and other offers. </p>
<p className="">•	Operate and communicate about social network pages or mobile applications. </p>
<p className="">•	Registration for newsletter subscription. </p>
<p className="">•	Comply with applicable legal requirements, relevant industry standards and the Data Controller’s policies. </p>
<p className="">6.	Secondary Purposes. </p>
<p className="">The Personal Data of the Data Subject will be processed for Secondary Purposes, which are those that are not required for the provision of services or are not related to the relationship with the Data Controller, such as the following: </p>
<p className="">•	Send promotional materials or other communications. </p>
<p className="">•	Deliver information about products and services, including the sale of memberships offered by VRCo. </p>
<p className="">•	Post product review. </p>
<p className="">•	Tailor ads displayed on the website and elsewhere to the client’s interests and history with the Data Controller. </p>
<p className="">•	Marketing. </p>
<p className="">The Data Controller will share the Personal Data of the Data Subject with service providers (Vrbo, Airbnb, Despegar, Expedia, Instagram, TikTok, Facebook platforms, among others) for the administration, storage and management of databases, automated processing of Personal Data, and other services of a similar nature to those described above. </p>
<p className="">In case the Data Subject does not want his/her Personal Data to be used for the Secondary Purposes described above, he/she may communicate it through the following e-mail: contact@vrco.site </p>
<p className="">7.	Use and disclosure limitation. </p>
<p className="">At any time, the Data Subject may limit the use and disclosure of his/her Personal Data by sending an e-mail to the following address: contact@vrco.site. </p>
<p className="">The Data Controller informs that the Personal Data of the Data Subject will be safeguarded at all times under the highest security standards, ensuring at all times the strictest confidentiality and privacy of the same, adhering at all times to the provisions of the Law, its Regulations and Guidelines. </p>
<p className="">8.	Personal Data Transfer. </p>
<p className="">The Data Subject hereby gives his/her consent for the Data Controller or any of its Data Processors to transfer Personal Data to its affiliates, related companies or third- party service providers, whether domestic or foreign, on the understanding that the processing that such third parties may give to the Data Subject's Personal Data shall comply with the provisions and Purposes of this Privacy Notice. </p>
<p className="">Subject to the provisions of the last paragraph of this Section 8, the Data Controller informs the Data Subject that in order to comply with its obligations, the Data Controller and/or its Data Processors have entered or will enter into various commercial agreements with service providers. The Data Subject's authorization granted pursuant to this Section 8 entitles the Data Controller and/or its Data Processors to transfer Personal Data of the Data Subject to such third parties, on the understanding that such third parties are obliged, by virtue of the corresponding agreement, to maintain the confidentiality of the Personal Data provided by the Data Controller and/or its Data Processors and to observe this Privacy Notice. </p>
<p className="">Notwithstanding the provisions of this Section 8 or any other Section of this Privacy Notice, the Data Subject acknowledges and agrees that the Data Controller does not require authorization or confirmation from such Data Subject in order to make national or international transfers of Personal Data in the cases set forth in Article 37 of the Law or in any other case of exception set forth in the Law, its Regulations or the Guidelines. </p>
<p className="">In case the Data Subject does not wish the Data Controller to transfer their Personal Data, he/she may express their refusal by sending a communication to the Data Controller at the following e-mail address: contact@vrco.site. </p>
<p className="">9.	Security Measures. </p>
<p className="">To prevent unauthorized access to the Personal Data of the Data Subject and in order to ensure that the information is used for the Purposes set forth in this Privacy Notice, the Data Controller has established physical, electronic and administrative procedures that limit the use or disclosure of the data of the Data Subject, allowing the Data Controller to process them properly. Additionally, the Personal Data of the Data Subject will be processed exclusively by those persons who carry out activities that require the knowledge of such data. </p>
<p className="">10.	Sensitive Personal Data, and Financial or Patrimonial Data. </p>
<p className="">Pursuant to Section 3 of this Privacy Notice, the Data Controller will collect and process Financial or Patrimonial Data necessary or appropriate for the Purposes. Financial or Patrimonial Data will be collected, maintained and processed with strict security and confidentiality for the purposes set forth in this Privacy Notice and in compliance with the provisions of the Law. </p>
<p className="">Pursuant to Section 4 of this Privacy Notice, the Data Controller will collect and process Sensitive Personal Data. Such data will be collected, maintained and processed with strict security and confidentiality for the purposes set forth in this Privacy Notice and in compliance with the provisions of the Law. </p>
<p className="">11.	Use of Cookies. </p>
<p className="">The correct functioning of the sites of the Data Controller, the Data Processor and third parties that provide services to them require the enabling of “cookies” in their browsers. “Cookies” are small data files that are stored on the hard disk of the computer equipment or electronic communications device of a user when browsing a specific Internet site, which allows the exchange of status information between said site and the user's browser. The above referred information may reveal means of session identification, authentication, or user preferences, as well as any data stored by the browser with respect to the corresponding website. Cookies are automatically accepted by most browsers by virtue of their default settings, although the Data Subject may adjust his/her browser preferences to accept or reject cookies. The Data Controller’s website uses cookies to keep track of the items included by the Data Subject and his/her abandonment of the website at checkout. </p>
<p className="">The deactivation of cookies may disable various functions of the websites or may cause them not to be displayed correctly. </p>
<p className="">12.	Exercise of ARCO Rights. </p>
<p className="">The Data Subject has at all times the right to access, rectify, cancel or oppose to the processing of his/her Personal Data (the “ARCO Rights”), as well as to revoke the consent given for the processing of the Personal Data; these rights may be asserted through the area in charge of the processing of Personal Data of the Data Controller, whose details appear below. It is important to note that although the exercise of the ARCO Rights of the Data Subject is free of charge, the Data Controller may charge the Data Subject for justified shipping and reproduction costs. </p>
<p className="">Such application must include, at least, the Data Subject’s name, complete home address and any other means to communicate the Data Controller’s reply to the Data Subject’s application, documents that acknowledge his/her identity, specifying, in a clear and precise manner, the Personal Data to which he/she requires access, rectification, update or cancellation, the elements or documents where the Personal Data may be located, and indicate the reasons for which the Data Subject wishes to access his/her Personal Data, or the reasons for which he/she considers that his/her Personal Data needs to be updated, rectified or cancelled. </p>
<p className="">Please consider that it is possible that the Data Controller may deny access to the Personal Data of the Data Subject or may not be able to rectify, cancel, block, or oppose the processing of all the Personal Data of the Data Subject in accordance with applicable laws, particularly in the following cases: </p>
<p className="">-	When the applicant is not the Data Subject of the Personal Data, or the legal representative is not duly authorized to do so; </p>
<p className="">-	When the applicant's Personal Data is not found in its database; </p>
<p className="">-	When third-party rights are infringed; </p>
<p className="">-	When there is a legal prohibition, or a decision of a competent authority that restricts access to Personal Data, or does not allow the rectification, cancellation or opposition of the same; and </p>
<p className="">-	When the rectification, cancellation or opposition has been previously made. </p>
<p className="">The Data Controller will provide its response within a maximum of 20 calendar days following the date in which the application for access, rectification, cancellation or opposition is received. If the response confirms that the request of the Data Subject is valid, such request shall become effective within the next 15 days of the reply of the Data Controller. </p>
<p className="">The Data Controller informs that the Data Subject may initiate the procedure of protection of ARCO Rights, with the National Institute of Access to the Information and Protection of Personal Data (Instituto Nacional de Acceso a la Información y Protección de Datos Personales) (INAI), within the 15 calendar days following the date in which the Data Controller fails to provide a satisfactory reply or when no reply is provided once the aforementioned period has elapsed. </p>
<p className="">13.	Amendment to the Privacy Notice. </p>
<p className="">Any changes to this Privacy Notice will be made available to the general public on the Data Controller websites and at its offices. It is the Data Subject's responsibility to periodically review the contents of the Privacy Notice at www.vrco.site </p>
<p className="">14.	Revocation. </p>
<p className="">In case the Data Subject does not want the Data Controller to process his/her Personal Data, the Data Subject must send us an application via electronic mail to the following address: contact@vrco.site, and will receive a reply from the Data Controller. In such case, the Data Controller will inform said consent withdrawal to the third parties that have access to the Personal Data of the Data Subject. </p>
<p className="">15.	Data Protection Special Unit. </p>
<p className="">If the Data Subject has any questions or require any clarifications, or wishes to exercise his/her ARCO Rights, please contact the Data Controller via the following electronic mail: contact@vrco.site, or at the Data Controller’s address located at Mexico City, Mexico. </p>
<p className="">Also, in case the Data Subject has any questions or concerns, he/she may contact the INAI at https://home.inai.org.mx/ </p>
<p className="">Issue date: February 10, 2023. </p>
      </div>
    </div>
  )
}