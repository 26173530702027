import { useContext, useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, GoogleAuthProvider, signInWithPopup, sendEmailVerification } from "firebase/auth";
import { DataContext } from '../context/DataContext';
import { useNavigate } from "react-router-dom";
import googleLogo from '../assets/google_logo.svg';
import { Link } from 'react-router-dom';
export default function Signup() {

  const { auth, setAuth, user, setUser } = useContext(DataContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  setAuth(getAuth());

  const navigate = useNavigate();

  const comparePasswords = (password, confirmPassword) => {
    if (password === confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const sendUserInfo = (user) => {
    fetch("https://vrco.azurewebsites.net//api/Signup", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uid: user.uid,
        email: user.email
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      }
      );
  };


  return (
    <section className="flex">
      <div className="container m-auto">
        <div className="flex justify-center h-full g-6 text-gray-800 pt-24">
          <div className="flex flex-col md:w-8/12 lg:w-1/2 justify-center">
            <form>
              <div className="mb-6">
                <input
                  type="text"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <input
                  type="password"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p className="text-sm text-gray-500 mt-2" style={{ display: password.length < 8 ? 'block' : 'none' }}>Password must be at least 8 characters long</p>
              </div>
              <div className="mb-6">
                <input
                  type="password"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Confirm password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <p className="text-sm text-gray-500 mt-2">{comparePasswords(password, confirmPassword) ? '' : 'Passwords do not match'}</p>
              </div>
              <div className="mb-6 bg-red-100 text-red-500 text-center rounded-md p-2" style={{ display: error === '' ? 'none' : 'block' }}>{error}
              </div>
              <button
                type="submit"
                className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full mb-2"
                disabled={email === '' || password === '' || confirmPassword === '' || !comparePasswords(password, confirmPassword)}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                onClick={(e) => {
                  e.preventDefault();
                  createUserWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                      // Signed in
                      setUser(userCredential.user);
                      console.log(user);
                      sendUserInfo(userCredential.user);
                      navigate('/dashboard');
                      // ...
                    })
                    .catch((error) => {
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      console.log(errorCode, errorMessage);
                      setError(errorMessage);
                    });
                }}

              >
                Sign up
              </button>
              <button
                type="submit"
                className="inline-block px-7 py-3 bg-gray-50 font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-200 active:shadow-lg transition duration-150 ease-in-out w-full text-black"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                onClick={(e) => {
                  e.preventDefault();
                  setPersistence(auth, browserLocalPersistence)
                    .then(() => {
                      const provider = new GoogleAuthProvider();
                      return signInWithPopup(auth, provider)
                        .then((result) => {
                          // This gives you a Google Access Token. You can use it to access the Google API.
                          const credential = GoogleAuthProvider.credentialFromResult(result);
                          const token = credential.accessToken;
                          // The signed-in user info.
                          const user = result.user;
                          setUser(user);
                          console.log(user);
                          sendUserInfo(user);
                          navigate('/dashboard');
                        }).catch((error) => {
                          // Handle Errors here.
                          const errorCode = error.code;
                          const errorMessage = error.message;
                          // The email of the user's account used.
                          const email = error.email;
                          // The AuthCredential type that was used.
                          const credential = GoogleAuthProvider.credentialFromError(error);
                          console.log(errorCode, errorMessage, email, credential);
                          setError(errorMessage);
                        });
                    })
                }}
              >
                <img src={googleLogo} alt="google logo" className="inline-block mr-2" />
                Continue with Google
              </button>
            </form>
          <div className="text-center mt-4">
            <p className="text-gray-800">
              Already have an account?
              <Link
                type="button"
                className="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out cursor-pointer"
                to="/login"
              >
                &nbsp;Login
              </Link>
            </p>
          </div>
          </div>
        </div>
      </div>
    </section>
  )
}