"use client"

import clsx from "clsx"
import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  DocumentIcon,
  DocumentTextIcon,
  UserIcon,
} from "@heroicons/react/24/outline"

import {
  SectionWrapper,
  SectionBadge,
  SectionHeading,
  SectionTitle,
  SectionTitleFade,
  SectionDescription,
} from "./Section"
import { ScrollReveal } from "./ScrollReveal"
import { SpotlightCard } from "./SpotlightCard"
import { Handshake, Lock, ShieldCheck, Shuffle, User, Users } from "lucide-react"

function FeatureCard({ children, className, color }) {
  return <SpotlightCard className={clsx("p-8", className)} color={color}>{children}</SpotlightCard>
}

function FeatureCardThumbnail({ children }) {
  return <div className="flex items-center justify-center gap-4 py-6">{children}</div>
}

function FeatureCardBody({ children }) {
  return <div className="mt-4">{children}</div>
}

function FeatureCardTitle({ children }) {
  return <div className="text-lg text-blue-800 font-medium">{children}</div>
}

function FeatureCardDescription({ children }) {
  return <p className="mt-4 text-sm font-light leading-relaxed text-black/90">{children}</p>
}

function DocumentsFeature({ className }) {
  const dots = new Array(5)

  return (
    <FeatureCard className={className} color="bg-zinc-100 border border-zinc-300 shadow-md">
      <FeatureCardThumbnail>
        <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-black/20 bg-white/5 shadow">
          <Users className="relative h-8 w-8 fill-white/10 stroke-[1.5] text-black" />
        </div>

        <div className="w-[3.5rem] overflow-hidden">
          <div className="flex w-max animate-marquee justify-end [animation-direction:reverse] [animation-duration:6s]">
            {[...dots].map((dot, index) => (
              <div key={index} className="px-1">
                <div className="h-1 w-1 shrink-0 rounded-full bg-black/30"></div>
              </div>
            ))}
          </div>
        </div>

        <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-black/20 bg-white/5 shadow">
          <Handshake className="relative h-8 w-8 fill-rose-400/10 stroke-[1.5] text-rose-400" />
        </div>
      </FeatureCardThumbnail>

      <FeatureCardBody>
        <FeatureCardTitle>User friendly</FeatureCardTitle>
        <FeatureCardDescription>
VRCo can be used by anybody. We have a very user-friendly interface that allows you to manage your reservations and follow up with your rentals at any time. Plus, our service helps make everything run smoothly.
        </FeatureCardDescription>
      </FeatureCardBody>
    </FeatureCard>
  )
}

function ResponsesFeature({ className }) {
  const dots = new Array(3)

  return (
    <FeatureCard className={className} color="bg-zinc-100 border border-zinc-300 shadow-md">
      <FeatureCardThumbnail>
        {/* <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-white/10 bg-white/5 shadow">
          <UserIcon className="relative h-8 w-8 fill-white/10 stroke-[1] text-white" />
        </div>

        <div className="w-9 overflow-hidden">
          <div className="flex w-max animate-marquee justify-end [animation-direction:reverse] [animation-duration:2s]">
            {[...dots, ...dots].map((dot, index) => (
              <div key={index} className="px-1">
                <div className="h-1 w-1 shrink-0 rounded-full bg-white/40"></div>
              </div>
            ))}
          </div>
        </div> */}

        <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-black/20 bg-white/5">
          <Shuffle className="relative h-8 w-8 fill-orange-400/10 stroke-[1.5] text-orange-400" />
        </div>

        {/* <div className="w-9 overflow-hidden">
          <div className="flex w-max animate-marquee justify-end [animation-direction:reverse] [animation-duration:2s]">
            {[...dots, ...dots].map((dot, index) => (
              <div key={index} className="px-1">
                <div className="h-1 w-1 shrink-0 rounded-full bg-white/40"></div>
              </div>
            ))}
          </div>
        </div>

        <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-white/10 bg-white/5 shadow">
          <DocumentTextIcon className="relative h-8 w-8 fill-teal-400/10 stroke-[1] text-teal-400" />
        </div> */}
      </FeatureCardThumbnail>

      <FeatureCardBody>
        <FeatureCardTitle>Flexible</FeatureCardTitle>
        <FeatureCardDescription>
        Upload and manage your properties with ease. VRCo makes it simple to handle reservations and rentals, whether you need to cancel, reschedule, or keep track of your listings.
        </FeatureCardDescription>
      </FeatureCardBody>
    </FeatureCard>
  )
}

function ReferencesFeature({ className }) {
  const dots = new Array(3)

  return (
    <FeatureCard className={className} color="bg-zinc-100 border border-zinc-300 shadow-md">
      <FeatureCardThumbnail>
        <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-black/20 bg-white/5 shadow">
          <User className="relative h-8 w-8 fill-white/10 stroke-[1.5] text-black" />
        </div>

        <div className="w-9 overflow-hidden">
          <div className="flex w-max animate-marquee justify-end [animation-direction:reverse] [animation-duration:2s]">
            {[...dots, ...dots].map((dot, index) => (
              <div key={index} className="px-1">
                <div className="h-1 w-1 shrink-0 rounded-full bg-amber-400/40"></div>
              </div>
            ))}
          </div>
        </div>

        <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-white/10 bg-white/5">
          <Lock className="relative h-8 w-8 fill-amber-400/10 stroke-[1.5] text-amber-400" />
        </div>

        <div className="w-9 overflow-hidden">
          <div className="flex w-max animate-marquee justify-end [animation-direction:reverse] [animation-duration:2s]">
            {[...dots, ...dots].map((dot, index) => (
              <div key={index} className="px-1">
                <div className="h-1 w-1 shrink-0 rounded-full bg-emerald-400/40"></div>
              </div>
            ))}
          </div>
        </div>

        <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl border border-black/20 bg-white/5 shadow">
          <ShieldCheck className="relative h-8 w-8 fill-emerald-400/10 stroke-[1.5] text-emerald-400" />
        </div>
      </FeatureCardThumbnail>

      <FeatureCardBody>
        <FeatureCardTitle>Secure</FeatureCardTitle>
        <FeatureCardDescription>
        VRCo is secure and safe to use. We use the latest security protocols to ensure that your data is always protected.
        </FeatureCardDescription>
      </FeatureCardBody>
    </FeatureCard>
  )
}

export function PrimaryFeatures() {
  return (
    <div id="overview" className="scroll-mt-8">
      {/* <ScrollReveal once={true} trigger="top" className="[--duration:500ms]"> */}
          <SectionWrapper>
            <SectionHeading>
              {/* <SectionBadge>Overview {isActive ? "true" : "false"}</SectionBadge> */}

              {/* <SectionTitle>
                Revolutionizing the way
                <SectionTitleFade>
                  you
                  <br />
                  understand documents
                </SectionTitleFade>
              </SectionTitle>

              <SectionDescription>
                Recharger lets you have a conversation with your documents.
                <br className="hidden lg:block" />
                Just ask and retrieve the information you need, all in a simple chat.
              </SectionDescription> */}
            </SectionHeading>

            <div className="mt-8 grid gap-4 md:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-8">
              <DocumentsFeature
                className={clsx(
                  " transition-all delay-150 duration-[--duration]",
                )}
              />
              <ResponsesFeature
                className={clsx(
                  " transition-all delay-300 duration-[--duration]",
                )}
              />
              <ReferencesFeature
                className={clsx(
                  " transition-all delay-[450ms] duration-[--duration]",
                )}
              />
            </div>
          </SectionWrapper>
      {/* </ScrollReveal> */}
    </div>
  )
}
