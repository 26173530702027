import React, { useState, useEffect } from "react";
import { resortData } from "../data";

const ResortFilterForm = () => {
  const [resorts, setResorts] = useState([]);
  const [names, setNames] = useState([]);
  const [destinations, setDestinations] = useState([]);

  const [selectedResort, setSelectedResort] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");

  useEffect(() => {
    console.log(resortData);
    const uniqueResorts = Array.from(
      new Set(
        resortData
          .filter((row) => row.disponibilidad === "Si")
          .map((row) => row.resort)
      )
    );
    setResorts(uniqueResorts);
  }, []);

  useEffect(() => {
    const filteredNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === selectedResort)
          .map((row) => row.name)
      )
    );
    setNames(filteredNames);
  
    // Set the default value for the "name" input when there is only one option available
    setSelectedName(filteredNames.length === 1 ? filteredNames[0] : "");
  }, [selectedResort]);

  useEffect(() => {
    const filteredDestinations = Array.from(
      new Set(
        resortData
          .filter(
            (row) => row.resort === selectedResort && row.name === selectedName
          )
          .map((row) => row.destino.split(",")) // Split the string by commas
          .flat() // Flatten the resulting array
      )
    );
    setDestinations(filteredDestinations);
    setSelectedDestination(
      filteredDestinations.length === 1 ? filteredDestinations[0] : ""
    );
  }, [selectedResort, selectedName]);  

  return (
    <div>
      <form className="space-y-4">
        <div>
          <label htmlFor="resort">Resort:</label>
          <select
            id="resort"
            value={selectedResort}
            onChange={(e) => setSelectedResort(e.target.value)}
            className="ml-2"
          >
            <option value="">Select a resort</option>
            {resorts.map((resort, index) => (
              <option key={index} value={resort}>
                {resort}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            id="name"
            value={selectedName}
            onChange={(e) => setSelectedName(e.target.value)}
            className="ml-2"
            list="name-datalist"
          />
          <datalist id="name-datalist">
            {names.map((name, index) => (
              <option key={index} value={name} />
            ))}
          </datalist>
        </div>
        <div>
          <label htmlFor="destination">Destination:</label>
          <select
            id="destination"
            value={selectedDestination}
            onChange={(e) => setSelectedDestination(e.target.value)}
            className="ml-2"
          >
            <option value="">Select a destination</option>
            {destinations.map((destination, index) => (
              <option key={index} value={destination}>
                {destination}
              </option>
            ))}
          </select>
        </div>
      </form>
    </div>
  );
};

export default ResortFilterForm;