export const resortData = [
  {
    "disponibilidad": "Si",
    "resort": "Mayan Palace",
    "name": "Master Room",
    "destino": "Mazatlan, Puerto Vallarta, Riviera Maya, Acapulco, Puerto Peñasco",
    "image": "resorts/mpmr.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Mayan Palace",
    "name": "Suite",
    "destino": "Mazatlan, Puerto Vallarta, Riviera Maya, Acapulco, Puerto Peñasco",
    "image": "resorts/mps.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Mayan Palace",
    "name": "Master Suite",
    "destino": "Mazatlan, Puerto Vallarta, Riviera Maya, Acapulco, Puerto Peñasco",
    "image": "resorts/mpms.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Bliss",
    "name": "Master Room",
    "destino": "Riviera Maya",
    "image": "resorts/tbmr.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Bliss",
    "name": "Suite",
    "destino": "Riviera Maya",
    "image": "resorts/tbs.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Bliss",
    "name": "Master Suite",
    "destino": "Riviera Maya",
    "image": "resorts/tbms.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Grand Mayan",
    "name": "Master Room",
    "destino": "Nuevo Vallarta, Riviera Maya, Los Cabos, Acapulco, Puerto Peñasco",
    "image": "resorts/tgmmr.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Grand Mayan",
    "name": "Suite",
    "destino": "Nuevo Vallarta, Riviera Maya, Los Cabos, Acapulco, Puerto Peñasco",
    "image": "resorts/tgms.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Grand Mayan",
    "name": "Master Suite",
    "destino": "Nuevo Vallarta, Riviera Maya, Los Cabos, Acapulco, Puerto Peñasco",
    "image": "resorts/tgmms.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Grand Bliss",
    "name": "Master Room",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/tgbmr.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Grand Bliss",
    "name": "Suite",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/tgbs.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Grand Bliss",
    "name": "Master Suite",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/tgbms.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Master Room",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/glmr.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Suite",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/gls.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Master Suite",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/glms.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Loft",
    "destino": "Nuevo Vallarta",
    "image": "resorts/gll.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Junior Villa",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/gljv.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Luxxe 1 Br Villa",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/gl1bv.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Luxxe 2 Br Master Villa",
    "destino": "Nuevo Vallarta, Riviera Maya",
    "image": "resorts/gl2bv.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Iv/Royal 1 Bedroom",
    "destino": "Nuevo Vallarta",
    "image": "resorts/glr1.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Iv/Royal 2 Bedroom",
    "destino": "Nuevo Vallarta",
    "image": "resorts/glr2.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Jungle / Deluxxe 1 Bedroom",
    "destino": "Riviera Maya",
    "image": "resorts/glj.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Presidential Suite 1 Bdm",
    "destino": "Nuevo Vallarta",
    "image": "resorts/glps.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Presidential Villa 1 Bdm",
    "destino": "Nuevo Vallarta",
    "image": "resorts/glpv1.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Presidential Villa 2 Bdm",
    "destino": "Nuevo Vallarta",
    "image": "resorts/glpv2.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Presidential Villa 3 Bdm",
    "destino": "Nuevo Vallarta",
    "image": "resorts/glpv3.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Spa 2 Bedroom",
    "destino": "Nuevo Vallarta, Rivera Maya",
    "image": "resorts/gls2.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Grand Luxxe",
    "name": "Grand Luxxe Spa 3 Bedroom",
    "destino": "Nuevo Vallarta, Rivera Maya",
    "image": "resorts/gls3.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Residence At Grand Luxxe",
    "name": "1 Bedroom Loft",
    "destino": "Nuevo Vallarta",
    "image": "resorts/rgl1b.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Residence At Grand Luxxe",
    "name": "2 Bedroom Loft",
    "destino": "Nuevo Vallarta, Rivera Maya",
    "image": "resorts/rgl2b.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Residence At Grand Luxxe",
    "name": "3 Bedroom Loft",
    "destino": "Nuevo Vallarta, Rivera Maya",
    "image": "resorts/rgl3l.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Residence At Grand Luxxe",
    "name": "4 Bedroom",
    "destino": "Nuevo Vallarta, Rivera Maya",
    "image": "resorts/rgl4b.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Estates",
    "name": "The Estates 1 Bedroom",
    "destino": "Nuevo Vallarta, Riviera Maya, East Cape (Coming Soon)",
    "image": "resorts/te1.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Estates",
    "name": "The Estates 2 Bedroom",
    "destino": "Nuevo Vallarta, Riviera Maya, East Cape (Coming Soon)",
    "image": "resorts/te2.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Estates",
    "name": "The Estates 3 Bedroom",
    "destino": "Nuevo Vallarta, Riviera Maya, East Cape (Coming Soon)",
    "image": "resorts/te3.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Estates",
    "name": "The Estates 4 Bedroom",
    "destino": "Nuevo Vallarta, Riviera Maya, East Cape (Coming Soon)",
    "image": "resorts/te4.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "The Estates",
    "name": "Estates Loft (East Cape)",
    "destino": "East Cape",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Empire Estates",
    "name": "The Empire Estates 1 Bedroom",
    "destino": "Nuevo Vallarta, East Cape (Coming Soon), Riviera Maya (Coming Soon)",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Empire Estates",
    "name": "The Empire Estates 2 Bedroom",
    "destino": "Nuevo Vallarta, East Cape (Coming Soon), Riviera Maya (Coming Soon)",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Empire Estates",
    "name": "The Empire Estates 3 Bedroom",
    "destino": "Nuevo Vallarta, East Cape (Coming Soon), Riviera Maya (Coming Soon)",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Empire Estates",
    "name": "The Empire Estates 4 Bedroom",
    "destino": "Nuevo Vallarta, East Cape (Coming Soon), Riviera Maya (Coming Soon)",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Empire Estates",
    "name": "Empire Estates Tower 2 Bdm",
    "destino": "Nuevo Vallarta, East Cape (Coming Soon), Riviera Maya (Coming Soon)",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Deluxxe At The Grand Mayan (Nuevo Vallarta)",
    "name": "Junior Suite",
    "destino": "Nuevo Vallarta",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Deluxxe At The Grand Mayan (Nuevo Vallarta)",
    "name": "1 Bedroom Bath Suite",
    "destino": "Nuevo Vallarta",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Deluxxe At The Grand Mayan (Nuevo Vallarta)",
    "name": "2 Bedroom Bath Suite",
    "destino": "Nuevo Vallarta",
    "image": "resorts/soon.jpg"
  },
  {
    "disponibilidad": "No",
    "resort": "Entertaintment Collection",
    "name": "Master Room",
    "destino": "Nuevo Vallarta",
  },
  {
    "disponibilidad": "No",
    "resort": "Entertaintment Collection",
    "name": "Suite",
    "destino": "Nuevo Vallarta",
  },
  {
    "disponibilidad": "No",
    "resort": "Entertaintment Collection",
    "name": "Master Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Entertaintment Collection",
    "name": "Master Room",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Entertaintment Collection",
    "name": "Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Entertaintment Collection",
    "name": "Master Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Celebrate Park (Cascade En Parque)",
    "name": "Master Room",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Celebrate Park (Cascade En Parque)",
    "name": "Junior Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Celebrate Park (Cascade En Parque)",
    "name": "Junior Loft",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Celebrate Park (Cascade En Parque)",
    "name": "Junior Suite Deluxxe",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Celebrate Park (Cascade En Parque)",
    "name": "1 Bedroom Deluxxe",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Celebrate Park (Cascade En Parque)",
    "name": "2 Bedroom Deluxxe",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Imperial",
    "name": "Master Room",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Imperial",
    "name": "1 Bedroom 2 Bath Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Imperial",
    "name": "2 Bedroom 3 Bath Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Imperial",
    "name": "Master Room",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Imperial",
    "name": "1 Bedroom 2 Bath Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Imperial",
    "name": "2 Bedroom 3 Bath Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Imperial Jungle Aqua",
    "name": "Master Room",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Imperial Jungle Aqua",
    "name": "Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Grand Imperial Jungle Aqua",
    "name": "Master Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Kingdom Of The Sun II (Parque)",
    "name": "Suite Park",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Kingdom Of The Sun II (Parque)",
    "name": "Suite Park Terrace",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "Si",
    "resort": "Kingdom Of The Sun III (Parque)",
    "name": "1 Bedroom 2 Bath Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "Si",
    "resort": "Kingdom Of The Sun III (Parque)",
    "name": "2 Bedroom 3 Bath Suite",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "Si",
    "resort": "Kingdom Of The Sun III (Parque)",
    "name": "2 Bedroom 3 Bath Loft",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Presidential Park And Grand Luxxe",
    "name": "2 Bedroom 3 Bath Presidential",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Presidential Park And Grand Luxxe",
    "name": "2 Bedroom 3 1/2 Bath Presidential Loft",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "No",
    "resort": "Presidential Park And Grand Luxxe",
    "name": "3 Bedroom 4 1/2 Bath Presidential Loft",
    "destino": "Nuevo Vallarta"
  },
  {
    "disponibilidad": "Si",
    "resort": "Le Blanc Los Cabos",
    "name": "Residence At Le Blanc Los Cabos",
    "destino": "Los Cabos",
    "image": "resorts/lblc.jpg"
  },
  {
    "disponibilidad": "Si",
    "resort": "Moon Palace Nizuc",
    "name": "Residence Palace Elite At Nizuc Cancun",
    "destino": "Cancun",
    "image": "resorts/mpn.jpg"
  },
  {
    "disponibilidad": "No",
    "resort": "Legacy Park",
    "name": "",
    "destino": "Nuevo Vallarta",
  },
  {
    "disponibilidad": "No",
    "resort": "Emperors",
    "name": "",
    "destino": "Nuevo Vallarta",
  },
]