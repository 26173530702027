import { cn } from "../lib/utils";
import Marquee from "./marquee";

// eviews from Users:

// 	1.	John D.
// 	•	“Fantastic service! Quick and seamless communication. Perfect for unused vacation weeks.”
// 	2.	Sarah L.
// 	•	“Easy to list my property. Great customer service and experience.”
// 	3.	Michael B.
// 	•	“Highly recommend! User-friendly and very responsive team.”

// Reviews from Guests:

// 	4.	Emily R.
// 	•	“Amazing stay! Excellent communication and easy booking.”
// 	5.	David M.
// 	•	“Best rental experience. Impeccable service and perfect spot.”
// 	6.	Jessica W.
// 	•	“Loved the ease of booking and quality accommodations. Quick support.”

const reviews = [
  {
    name: "John D.",
    username: "Owner",
    body: "Fantastic service! Quick and seamless communication. Perfect for unused vacation weeks.",
    img: "https://avatar.vercel.sh/jack"
  },
  {
    name: "Sarah L.",
    username: "Guest",
    body: "Easy to list my property. Great customer service and experience.",
    img: "https://avatar.vercel.sh/sarah"
  },
  {
    name: "Michael B.",
    username: "Owner",
    body: "Highly recommend! User-friendly and very responsive team.",
    img: "https://avatar.vercel.sh/michael"
  },
  {
    name: "Emily R.",
    username: "Guest",
    body: "Amazing stay! Excellent communication and easy booking.",
    img: "https://avatar.vercel.sh/emily"
  },
  {
    name: "David M.",
    username: "Owner",
    body: "Best rental experience. Impeccable service and perfect spot.",
    img: "https://avatar.vercel.sh/david"
  },
  {
    name: "Jessica W.",
    username: "Owner",
    body: "Loved the ease of booking and quality accommodations. Quick support.",
    img: "https://avatar.vercel.sh/jessica"
  },
    
];

const ReviewCard = ({
  img,
  name,
  username,
  body,
}) => {
  return (
    <figure
      className={cn(
        "relative w-80 overflow-hidden rounded-xl border p-4",
        // light styles
        "border-indigo-200 bg-gradient-to-br from-white to-zinc-100 text-black hover:from-white hover:to-zinc-200 transition-all duration-300",
        // dark styles
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium">
            {name}
          </figcaption>
          <p className="text-xs font-medium">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  );
};

const MarqueeDemo = () => {
  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-lg mt-2 mb-28">
      <h2 className="text-4xl font-bold leading-tight sm:text-4xl lg:text-5xl text-center  text-transparent bg-clip-text bg-gradient-to-br gradient-text pt-12 pb-8">What our customers are saying</h2>

      <Marquee className="[--duration:20s]">
        {reviews.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      {/* <Marquee reverse className="[--duration:20s]">
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee> */}
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/12 bg-gradient-to-r from-white"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/12 bg-gradient-to-l from-white"></div>
    </div>
  );
};

export default MarqueeDemo;