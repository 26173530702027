export default function TermsAndConditions() {
  return (
    <div className="container mx-auto mt-12">
      <div className="terms px-8 md:px-30 xl:px-80 py-8 text-justify">
<p className="">TERMS AND CONDITIONS </p>
<p className="">Last updated February 13, 2023 </p>
<p className="">TABLE OF CONTENTS </p>
<p className="">1.	AGREEMENT TO TERMS </p>
<p className="">2.	INTELLECTUAL PROPERTY RIGHTS </p>
<p className="">3.	USER REPRESENTATIONS </p>
<p className="">4.	PRODUCTS AND MEMBERSHIP T&C </p>
<p className="">5.	PURCHASES AND PAYMENT </p>
<p className="">6.	REFUNDS POLICY </p>
<p className="">7.	PROHIBITED ACTIVITIES </p>
<p className="">8.	USER GENERATED POSTS </p>
<p className="">9.	CONTRIBUTION LICENSE </p>
<p className="">10.	SUBMISSIONS </p>
<p className="">11.	SITE MANAGEMENT </p>
<p className="">12.	PRIVACY POLICY </p>
<p className="">13.	TERM AND TERMINATION </p>
<p className="">14.	MODIFICATIONS AND INTERRUPTIONS </p>
<p className="">15.	GOVERNING LAW; JURISDICTION, CLASS ACTION WAIVER </p>
<p className="">16.	CORRECTIONS </p>
<p className="">17.	DISCLAIMER </p>
<p className="">18.	LIMITATIONS OF LIABILITY </p>
<p className="">19.	INDEMNIFICATION </p>
<p className="">20.	ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES </p>
<p className="">21.	COMPLAINTS </p>
<p className="">22.	MISCELLANEOUS </p>
<p className="">23.	CONTACT US </p>
<p className="">1.	AGREEMENT TO TERMS </p>
<p className="">These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“You”) and Vacation Rental Connections ("Company", “we”, “us”, or “our”), concerning your access to and use of the Company’s website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). </p>
<p className="">You agree that by accessing the Site, you have read, understood, and agreed to be bound by all these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY. </p>
<p className="">Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference, including without limitation, the Privacy Notice and the Unit or Property Rental Agreement form (jointly the “Agreements”). We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use, the Privacy Notice and the Unit or Property Rental Agreement, from time to time, and it is your responsibility to check whether such documents have been updated. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, the Privacy Notice and the Unit or Property Rental Agreement, and you waive any right to receive specific notice of such changes and/or amendments. </p>
<p className="">Please ensure that you check the applicable Agreements every time you use our Site so that you understand which Terms apply. You will be subject to and will be deemed to have been made aware of and to have accepted, the changes in any revised Agreements by the mere use of continued use of the Site after the date such revised Agreements are posted. </p>
<p className="">The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. </p>
<p className="">The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Site. </p>
<p className="">2.	INTELLECTUAL PROPERTY RIGHTS </p>
<p className="">Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of Mexico, international copyright laws, and international conventions. The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. </p>
<p className="">Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission. </p>
<p className="">Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks. </p>
<p className="">3.	USER REPRESENTATIONS </p>
<p className="">By using the Site, you represent and warrant that: </p>
<p className="">(1)	you have the legal capacity and you agree to comply with the Terms of Use and the Agreements; </p>
<p className="">(2)	you are not a minor in the jurisdiction in which you reside; </p>
<p className="">(3)	you will not access the Site through automated or non-human means, whether through a bot, script or otherwise; </p>
<p className="">(4)	you will not use the Site for any illegal or unauthorized purpose; and </p>
<p className="">(5)	your use of the Site will not violate any applicable law or regulation in Mexico or any other jurisdiction. </p>
<p className="">If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof). </p>
<p className="">4.	PRODUCTS AND MEMBERSHIP T&C </p>
<p className="">All products are subject to availability. We reserve the right to discontinue any products at any time for any reason. Prices for all products are subject to change. </p>
<p className="">This section titled VRCo Membership Terms and Conditions, set forth the agreement entered between VRCo and the individuals who purchased a membership VRCo at vrco.site referred to as “Customers” or “Purchaser”. </p>
<p className="">Payment for Membership benefits are due in advance. If Member fails to pay any membership fees or other fee or charge contemplated in the Agreements, the Company may terminate the Agreements without prior written notice. The term of the membership begins in the Effective Date (as such term is defined in the Unit or Property Rental Agreement) and continues until cancelled or terminated as provided herein and/or in the Agreements by you or the Company. </p>
<p className="">FOR ALL RENTAL TRANSACTIONS OF UNIT INTERVALS OR PROPERTIES, PLEASE REFER TO THE APPLICABLE TERMS AND CONDITIONS OF THE PLATFORM ON WHICH THE CORRESPONDING TOURIST SERVICE HAS BEEN ENTERED ACCORDING TO THE INSTRUCTIONS RECEIVED FROM THE COMPANY. </p>
<p className="">We do not represent, or act, for either the buyer or the seller, nor do we act as an intermediary between the prospective parties to a rental transaction. As a result, we have no control over the quality, safety, or legality of the properties posted, the truth or accuracy of the postings, the ability of members to rent or transfer its right to use and enjoy the unit interval or unit, or the ability of clients to complete the purchase. </p>
<p className="">5.	PURCHASES AND PAYMENT </p>
<p className="">We accept the following forms of payment: </p>
<p className="">-	Visa </p>
<p className="">-	Mastercard </p>
<p className="">-	PayPal </p>
<p className="">You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Site. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. In this regard, you acknowledge that any payments to be made by the Company to your account shall be made in the legal currency of Mexico; therefore, regardless the fact that such amounts can or may be expressed in dollars of the United States of America, they will be considered to be paid in full, in case it is paid in Pesos at the exchange rate published by the Bank of Mexico (Banco de Mexico) on the date of the corresponding payment. </p>
<p className="">In the event that you do not have an account that allows the reception of payments in Mexican pesos, the Company shall be entitled to withhold the amounts corresponding to you, until your account allows the reception of payments in Mexican pesos. </p>
<p className="">You agree to pay the Company the Administration Fee (as such term is defined in the Unit or Property Rental Agreement), cancelation fees, third parties fee, and all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment method for any such amounts. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. </p>
<p className="">We reserve the right to refuse any order placed through the Site. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors. </p>
<p className="">6.	REFUNDS POLICY </p>
<p className="">All sales are final and no refund will be issued. </p>
<p className="">7.	PROHIBITED ACTIVITIES </p>
<p className="">You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. </p>
<p className="">As a user of the Site, you agree not to: </p>
<p className="">(1)	Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us. </p>
<p className="">(2)	Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords. </p>
<p className="">(3)	Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein. </p>
<p className="">(4)	Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site. </p>
<p className="">(5)	Use any information obtained from the Site in order to harass, abuse, or harm another person. </p>
<p className="">(6)	Make improper use of our support services or submit false reports of abuse or misconduct. </p>
<p className="">(7)	Use the Site in a manner inconsistent with any applicable laws or regulations. </p>
<p className="">(8)	Engage in unauthorized framing of or linking to the Site. </p>
<p className="">(9)	Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site. </p>
<p className="">(10)	Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools. </p>
<p className="">(11)	Delete the copyright or other proprietary rights notice from any Content. </p>
<p className="">(12)	Attempt to impersonate another user or person or use the username of another user. </p>
<p className="">(13)	Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”). </p>
<p className="">(14)	Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site. </p>
<p className="">(15)	Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you. </p>
<p className="">(16)	Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site. </p>
<p className="">(17)	Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code. </p>
<p className="">(18)	Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site. </p>
<p className="">(19)	Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software. </p>
<p className="">(20)	Use a buying agent or purchasing agent to make purchases on the Site. </p>
<p className="">(21)	Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses. </p>
<p className="">(22)	Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise. </p>
<p className="">(23)	Use the Site to advertise or offer to sell goods and services. </p>
<p className="">(24)	Sell or otherwise transfer your profile. </p>
<p className="">8.	USER GENERATED POSTS </p>
<p className="">The Site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Posts"). </p>
<p className="">Posts may be viewable by other users of the Site and in third-party websites. As such, any Posts you transmit may be treated as non-confidential and non-proprietary. </p>
<p className="">9.	CONTRIBUTION LICENSE </p>
<p className="">By posting your Posts to any part of the Site , you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Posts (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Posts, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels. </p>
<p className="">This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Posts, and you warrant that moral rights have not otherwise been asserted in your Posts. </p>
<p className="">We do not assert any ownership over your Posts. You retain full ownership of all of your Posts and any intellectual property rights or other proprietary rights associated with your Posts and we are not liable for any statements or representations in your Posts provided by you in any area on the Site. You are solely responsible for your Posts to the Site and you expressly agree to defend, indemnify and hold us harmless, from any and all responsibility and to refrain from any legal action against us regarding your Posts. </p>
<p className="">We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Posts; (2) to re-categorize any Posts to place them in more appropriate locations on the Site; and (3) to pre-screen or delete any Posts at any time and for any reason, without notice. We have no obligation to monitor your Posts. </p>
<p className="">10.	SUBMISSIONS </p>
<p className="">You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site or third parties’ websites ("Submissions") provided by you to us are non-confidential and shall become our sole property. </p>
<p className="">We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. </p>
<p className="">You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions. </p>
<p className="">11.	SITE MANAGEMENT </p>
<p className="">We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use and the Agreements; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use and the Agreements, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Posts or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site. </p>
<p className="">12.	PRIVACY POLICY </p>
<p className="">We care about data privacy and security. By using the Site or third party platforms through which the unit intervals or the properties are being offered, you agree to be bound by our Privacy Policy posted on the Site, which are available at vrco.site. </p>
<p className="">Please be advised the Site and the transactions are executed and hosted in Mexico. </p>
<p className="">If you access the Site from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Mexico, then through your continued use of the Site, you are transferring your data to Mexico, and you expressly consent to have your data transferred to and processed in Mexico by VRCo or any third party related to VRCo. </p>
<p className="">13.	TERM AND TERMINATION </p>
<p className="">These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE AND YOUR MEMBERSHIP, OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. </p>
<p className="">If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress. </p>
<p className="">14.	MODIFICATIONS AND INTERRUPTIONS </p>
<p className="">We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Post published in third parties’ websites related to your properties or unit intervals, without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site or the Posts in third parties’ websites related to your properties or unit intervals . </p>
<p className="">We cannot guarantee the Site and the Post in third parties’ websites related to your properties or unit intervals will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site or third parties’ websites at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site or third parties websites during any downtime or discontinuance of the Site or third parties’ websites. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or third parties’ websites or to supply any corrections, updates, or releases in connection therewith. </p>
<p className="">15.	GOVERNING LAW; JURISDICTION; AND CLASS ACTION WAIVER </p>
<p className="">THESE TERMS SHALL BE GOVERNED BY AND DEFINED FOLLOWING THE LAWS OF MEXICO. VACATION RENTAL CONNECTIONS AND YOU IRREVOCABLY CONSENT THAT ANY DISPUTE OR CONTROVERSE ARISING FROM OR IN CONNECTION WITH THESE TERMS OF USE OR THE AGREEMENT, THE COURTS OF MEXICO SHALL HAVE EXCLUSIVE JURISDICTION TO RESOLVE ANY DISPUTE WHICH MAY ARISE IN CONNECTION WITH THESE TERMS; THEREFORE, BY ACCEPTING THE AGREEMENTS, YOU EXPRESSLY AND IRREVOCABLY WAIVE ANY OTHER JURISDICTION THAT MAY BE APPLICABLE DUE TO YOUR PRESENT OR FUTURE DOMICILE. </p>
<p className="">YOU AND THE COMPANY AGREE THAT ANY PROCEEDING TO RESOLVE OR LITIGATE ANY DISPUTE, WHETHER IN COURT, OR OTHERWISE, WILL BE CONDUCTED SOLELY ON AN </p>
<p className="">INDIVIDUAL BASIS, AND THAT NEITHER YOU NOR THE COMPANY WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, A REPRESENTATIVE ACTION, A COLLECTIVE ACTION, OR IN ANY PROCEEDING IN WHICH YOU OR US ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. </p>
<p className="">IF THE CLASS ACTION WAIVER HEREIN IS FOUND TO BE ILLEGAL OR UNENFORCEABLE AS TO ALL OR SOME PARTS OF A DISPUTE, WHETHER BY JUDICIAL, LEGISLATIVE, OR OTHER ACTION, THEN THIS SECTION WILL NOT APPLY TO THOSE PARTS. INSTEAD, THOSE PARTS OF THE DISPUTE WILL BE SEVERED AND PROCEED IN A COURT OF LAW. </p>
<p className="">16.	CORRECTIONS </p>
<p className="">There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to your unit interval or property, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice. </p>
<p className="">17.	DISCLAIMER </p>
<p className="">THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THIS SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. </p>
<p className="">18.	LIMITATIONS OF LIABILITY </p>
<p className="">VRCo SHALL NOT BE LIABLE FOR AND DO NOT ASSUME ANY LIABILITY OR RESPONSIBILITY FOR ANY LOSS, DAMAGE, DELAY, DEATH OR INJURT TO INDIVIDUALS OR PROPERTIES ARISING FROM OR AS A RESULT OF THE ACT OR OMISSIONS, RELATING TO THE </p>
<p className="">MEMBERSHIP AND SERVICES PURCHASED THROUGH VRCo, INCLUDING WITHOUT LIMITATION ANY RENTAL OFFERING OR BOOKING AND TRAVEL RESERVATIONS. </p>
<p className="">IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>
<p className="">NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX </p>
<p className="">(6)	MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN MEXICAN LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. </p>
<p className="">19.	INDEMNIFICATION </p>
<p className="">YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS US, OUR AFFILIATES AND THIRD- PARTY PROVIDERS AND THEIR CORRESPONDING EMPLOYEES, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS FROM AND AGAINST ALL CLAIMS, INCLUDING BUT NOT LIMITED TO, THIRD PARTY CLAIMS, DAMAGES, COSTS AND LIABILITIES, INCLUDING REASONABLE ATTORNEY’S FEES ARISING OUT OF OR IN CONNECTION WITH, YOUR VIOLATION OF THESE TERMS OF SERVICE OR ANY OF THE PROVISIONS SET FORTH IN THE AGREEMENTS. </p>
<p className="">20.	ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES </p>
<p className="">Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive </p>
<p className="">any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means. </p>
<p className="">21.	COMPLAINTS </p>
<p className="">If any complaint with us is not satisfactorily resolved, you can contact the Mexico's Federal Consumer Protection Agency (Procuraduria Federal del Consumidor) in writing. </p>
<p className="">22.	MISCELLANEOUS </p>
<p className="">These Terms of Use, the Agreements and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Agreements shall not operate as a waiver of such right or provision. </p>
<p className="">These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. </p>
<p className="">We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use or the Agreements is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable and does not affect the validity and enforceability of any remaining provisions. You agree that these Terms of Use and the Agreements will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use. </p>
<p className="">23.	CONTACT US </p>
<p className="">In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at: Vacation Rental Connections (contact@vrco.site) </p>
      </div>
    </div>
  )
}