import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../context/DataContext'
import { useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'
import { NavLink } from 'react-router-dom';


export default function Navbar() {

  const { user, setUser } = useContext(DataContext)

  const navigate = useNavigate()

  const handleLogout = () => {
    const auth = getAuth()
    signOut(auth).then(() => {
      setUser(null)
      handleClose()
      navigate('/')
    }).catch((error) => {
      console.log(error)
    })
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Popover className="relative bg-slate-900">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-6">
          <div className="flex justify-start xl:flex-1">
            <Link to="/">
              <span className="sr-only">VRCo</span>
              <img
                className="h-10 w-auto"
                src="/vrco.svg"
                alt="VRCo"
              />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-slate-800 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={handleOpen}>
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:space-x-6 space-x-10 md:flex">
            <NavLink to="/" activeClassName="font-bold" className="text-base font-medium text-white hover:text-indigo-200">
              Home
            </NavLink>
            <NavLink to="/how-it-works" activeClassName="font-bold" className="text-base font-medium text-white hover:text-indigo-200">
              How it works
            </NavLink>
            <NavLink to="/rental-commissions" activeClassName="font-bold" className="text-base font-medium text-white hover:text-indigo-200">
              Properties
            </NavLink>
            <NavLink to="/rental-service" activeClassName="font-bold" className="text-base font-medium text-white hover:text-indigo-200">
              Vacation Booking
            </NavLink>
            <NavLink to="/contact-us" activeClassName="font-bold" className="text-base font-medium text-white hover:text-indigo-200">
              Contact Us
            </NavLink>
          </Popover.Group>
          {user ? (
            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              <div className="whitespace-nowrap text-base font-medium text-white hover:text-indigo-200 cursor-pointer" onClick={handleLogout}>
                Logout
              </div>
              <Link to="/dashboard" className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700">
                Dashboard
              </Link>
            </div>
          ) : (
            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0 ">
              <Link to="/signup" className="whitespace-nowrap text-base font-medium text-white hover:text-blue-200">
                Sign up
              </Link>
              <Link
                to="/login"
                className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
              >
                Login
              </Link>
            </div>
          )}
        </div>
      </div>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-50">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-slate-800 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-20 w-auto"
                    src="/logo-white.png"
                    alt="VRCo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-slate-800 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={handleClose}>
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link to="/" className="text-base font-medium text-white hover:text-indigo-200" onClick={handleClose}>
                  Home
                </Link>
                <Link to="/how-it-works" className="text-base font-medium text-white hover:text-indigo-200" onClick={handleClose}>
                  How it works
                </Link>
                <Link to="/rental-commissions" className="text-base font-medium text-white hover:text-indigo-200" onClick={handleClose}>
                  Properties
                </Link>
                <Link to="/rental-service" className="text-base font-medium text-white hover:text-indigo-200" onClick={handleClose}>
                  Vacation Booking
                </Link>
                <Link to="/contact-us" className="text-base font-medium text-white hover:text-indigo-200" onClick={handleClose}>
                  Contact Us
                </Link>
              </div>
              <div>
                {user ? (
                  <>
                    <div className="dashboard text-center pb-2">
                      <Link to="/dashboard" className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700" onClick={handleClose}>
                        Dashboard
                      </Link>
                    </div>
                    <div className="flex w-full items-center justify-center text-base rounded-md border border-transparent font-medium text-white hover:text-indigo-200" onClick={handleLogout}>
                      Logout
                    </div>
                  </>
                ) : (
                  <>
                    <Link
                      to="/signup"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700" onClick={handleClose}
                    >
                      Sign up
                    </Link>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?{' '}
                      <Link to="/login" className="text-indigo-600 hover:text-indigo-500" onClick={handleClose}>
                        Sign in
                      </Link>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
