import { Link } from "react-router-dom";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ChevronRight } from "lucide-react";
import { HeroHighlight, Highlight } from "./hero-highlight";

const beachLED = []

//create same array randomly choosing between indigo-500 and purple-800
for (let i = 0; i < 215; i++) {
  let random = Math.floor(Math.random() * 3);
  if (random === 0) {
    beachLED[i] = 'bg-blue-600/50';
  } else if (random === 1) {
    beachLED[i] = 'bg-indigo-600/50';
  } else {
    beachLED[i] = '';
  }
}

const LED = forwardRef(({ color }, ref) => {
  const [opacity, setOpacity] = useState(1);

  const handleClick = () => {
    setOpacity(0);
    setTimeout(() => setOpacity(1), 3000);
  };

  useImperativeHandle(ref, () => ({
    handleClick,
  }));

  return (
    <div className={`w-3 h-3 m-0.5 rounded-full ${color} hover:brightness-50 transition duration-50 ease-in-out`} onMouseEnter={handleClick} style={{ opacity }} />
  );
});

const LEDDisplay = ({ ledArray }) => {
  const [ledRefs, setLedRefs] = useState([]);

  useEffect(() => {
    setLedRefs((refs) => Array(ledArray.length).fill().map(() => React.createRef()));
  }, [ledArray]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * ledArray.length);
      ledRefs[randomIndex].current.handleClick();
    }, 1000);
    return () => clearInterval(interval);
  }, [ledRefs]);

  return (
    <div className="flex flex-wrap justify-center items-center pt-8">
      {ledArray.map((color, index) => (
        <LED key={index} color={color} ref={ledRefs[index]} />
      ))}
    </div>
  );
};

export default function Hero() {
  return (
    <HeroHighlight>
      <main className="mx-auto max-w-7xl px-4 lg:px-8">
        <div className="text-center w-full h-full">
          <div className="flex flex-col w-full mx-auto pt-8">
            <div className="pt-8 w-full h-full">
              <h1 className="text-balance bg-gradient-to-br from-black/90 from-30% to-black/50 bg-clip-text py-6 text-5xl font-medium leading-none tracking-tighter text-transparent  sm:text-6xl md:text-7xl lg:text-8xl">
                Empowering Owners
              </h1>
            </div>
            <div className=" w-full h-full">
              <h1 className="text-balance bg-gradient-to-br from-black/90 from-30% to-black/50 bg-clip-text py-6 text-5xl font-medium leading-none tracking-tighter text-transparent  sm:text-6xl md:text-7xl lg:text-8xl">
                Vacation Rental Connections
              </h1>
            </div>
            {/* <div className="text-center w-full h-full">
            <h1 className="font-bold tracking-tight leading-relaxed md:leading-relaxed w-full h-full custom-text-size">
              Profit from Your Unused Weeks
            </h1>
          </div> */}
          </div>
          <p class="py-4 text-xl md:text-2xl font-medium gradient-text mx-auto sm:max-w-xl bg-sky-500">Our simplified promotion process allows you to effortlessly <Highlight className="text-black">maximize profits from unused weeks.</Highlight>
          </p>
          <div className="mt-5 sm:mt-8 sm:flex justify-center">
            <div className="">
              <div>
                <Link
                  to="/login"
                  className="bg-indigo-500 text-white shadow hover:bg-sky-600 group relative inline-flex h-10 w-full items-center justify-center gap-2 overflow-hidden whitespace-pre rounded-full px-6 py-2 text-base font-semibold tracking-tighter focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 md:flex transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2">
                  Get started
                  <ChevronRight strokeWidth={4} className="size-4 translate-x-0 transition-all duration-300 ease-out group-hover:translate-x-1" />
                </Link>
              </div>
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3">
            <a
              href="#"
              className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200 md:py-4 md:px-10 md:text-lg"
            >
              Tutorial
            </a>
          </div> */}
          </div>
        </div>
        {/* <LEDDisplay ledArray={beachLED} /> */}
        {/* <img src="/palm-trees.png" alt="mapa" className="mt-10 rounded-xl overflow-hidden" /> */}
      </main>
    </HeroHighlight>
  )
}