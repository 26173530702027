import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import Signup from './pages/Signup';
import Login from './pages/Login';
import How from './pages/How';
import Rental from './pages/Rental';
import Contact from './pages/Contact';
import RentalService from './pages/RentalService';
import { useContext } from 'react';
import { DataContext } from './context/DataContext';
import Dashboard from './pages/Dashboard';
import { useEffect } from 'react';
import Success from './pages/Success';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Test from './pages/Test';
import Admin from './pages/Admin';
import RentalAgreement from './components/RentalAgreement';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {

  const { user, setUser, app, setApp } = useContext(DataContext);


  const firebaseConfig = {
    apiKey: "AIzaSyDaHvYPp8uS--8uZk15E4Zv2lzwbuKrdDI",
    authDomain: "vrco-50810.firebaseapp.com",
    projectId: "vrco-50810",
    storageBucket: "vrco-50810.appspot.com",
    messagingSenderId: "274006006970",
    appId: "1:274006006970:web:36b01de001fda5584cf683",
    measurementId: "G-57MEC05YJM"
  };

  if (!app) {
    setApp(initializeApp(firebaseConfig));
  }


  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })
  }, []);

  return (
    <Router>
      <Navbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* redirect to dashboard if user is logged in */}
        {user ? <Route exact path="/login" element={<Dashboard />} /> : <Route exact path="/login" element={<Login />} />}
        {user ? <Route exact path="/signup" element={<Dashboard />} /> : <Route exact path="/signup" element={<Signup />} />}
        <Route path="/how-it-works" element={<How />} />
        <Route path="/rental-commissions" element={<Rental />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/success" element={<Success />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/test" element={<Test />} />
        <Route path="/rental-service" element={<RentalService />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/rental-agreement" element={<RentalAgreement />} />
      </Routes>
    </Router>
  );
}

export default App;
