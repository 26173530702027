"use client"
import { cn } from "../lib/utils"
import { useMotionValue, motion, useMotionTemplate } from "framer-motion"
import React from "react"

export const HeroHighlight = ({ children, className, containerClassName }) => {
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function handleMouseMove({ currentTarget, clientX, clientY }) {
    if (!currentTarget) return
    let { left, top } = currentTarget.getBoundingClientRect()

    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }
  return (
    <div
      className={cn(
        "relative h-[36rem] flex items-center bg-white justify-center w-full group",
        containerClassName
      )}
      onMouseMove={handleMouseMove}
    >
      <div className="absolute inset-0 bg-dot-thick-neutral-400  pointer-events-none [mask-image:radial-gradient(ellipse_at_top_left,white_35%,transparent,transparent)]" />
      <motion.div
        className="pointer-events-none bg-dot-thick-sky-500  absolute inset-0 opacity-0 transition duration-300 group-hover:opacity-100 [mask-image:radial-gradient(ellipse_at_top_left,white_35%,transparent,transparent)]"
        style={{
          WebkitMaskImage: useMotionTemplate`
            radial-gradient(
              200px circle at ${mouseX}px ${mouseY}px,
              black 0%,
              transparent 100%
            )
          `,
          maskImage: useMotionTemplate`
            radial-gradient(
              200px circle at ${mouseX}px ${mouseY}px,
              black 0%,
              transparent 100%
            )
          `
        }}
      />

      <div className={cn("relative z-20", className)}>{children}</div>
    </div>
  )
}

export const Highlight = ({ children, className }) => {
  return (
    <motion.span
      initial={{
        backgroundSize: "0% 100%"
      }}
      animate={{
        backgroundSize: "100% 100%"
      }}
      transition={{
        duration: 2,
        ease: "linear",
        delay: 1
      }}
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        display: "inline"
      }}
      className={cn(
        `relative inline-block pb-1 px-1 rounded-lg bg-gradient-to-r from-sky-400/50 to-blue-600/50`,
        className
      )}
    >
      {children}
    </motion.span>
  )
}
