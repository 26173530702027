import Companies from "../components/Companies";
import Hero from "../components/Hero";
import MoreInfo from "../components/MoreInfo";
// import Feature from "../components/Feature";
import Footer from "../components/Footer";
import MarqueeDemo from "../components/reviews";

export default function Home() {
  return (
    <div>
      <Hero />
      <MarqueeDemo />
      <MoreInfo />
      <Companies />
      <Footer />
    </div>
  )
}